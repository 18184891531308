import { Unauthorized } from "../../user/errors/Unauthorized";
import { EMPTY_USER_STATE } from "../../user/constants/userStatus";
import React from "react";

export const removeErrorWhenTyping = (
    errorsList, setErrorsList, field, index
) => {
    if (index === undefined) {
        errorsList[field]?.length &&
            setErrorsList({ ...errorsList, [field]: [] });
    } else {
        errorsList[field][index]?.length &&
        setErrorsList({
            ...errorsList, [field]: { ...errorsList[field], [index]: [] }
        });
    }
};

export const displayError = (error, index) =>
    <div key={ index } className="error-message">{ error }</div>;

export const catchUnauthorized = (error, setUser) =>
    error instanceof Unauthorized && setUser(EMPTY_USER_STATE);

export const createRefsById = (object) =>
    object.reduce((acc, element) => {
        acc[element.id] = React.createRef(null);

        return acc;
    }, {});

export const processDownload = (downloadPromise, fileName) => downloadPromise
    .then((response) => {
        const reader = response.body.getReader();

        return new ReadableStream({
            start(controller) {
                const read = () => reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();

                        return;
                    }
                    controller.enqueue(value);

                    return read();
                });

                return read();
            },
        });
    })
    .then((stream) => new Response(stream))
    .then((response) => response.blob())
    .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const linkElement = document.createElement("a");
        linkElement.style.display = "none";
        linkElement.href = url;
        linkElement.download = `${fileName}.zip`;

        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
    });

export const capitalizeString = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

