import React from "react";
import { INPUT_MAXLENGTH } from "../constants/maxInputLength";

const ConfigurationsTab = ({
    component,
    onConfigurationSectionChange,
    onConfigurationChange,
}) => {
    const displayConfigurationSection = (configurationSection) => (
        <div key={ configurationSection.machineName }>
            <h5>{ configurationSection.title }</h5>
            <ul className="mb-3">
                <li>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        maxLength={ INPUT_MAXLENGTH }
                        value={ configurationSection.description }
                        onChange={ e => onConfigurationSectionChange(
                            configurationSection.machineName, e.target.value)
                        }
                    />
                    <small>max { INPUT_MAXLENGTH } characters</small>
                </li>
                {
                    component.configurations
                        .filter((configuration) =>
                            configuration.sectionMachineName ===
                            configurationSection.machineName
                        )
                        .map(displayConfiguration)
                }
                {
                    configurationSection.configurationSections.length > 0 &&
                    configurationSection.configurationSections
                        .map(displayConfigurationSection)
                }
            </ul>
        </div>
    );

    const displayConfiguration = (configuration) => (
        <li className="mb-3" key={ configuration.machineName }>
            <label className="form-label">
                { configuration.title }
            </label>
            <input
                type="text"
                className="form-control"
                placeholder="Description"
                maxLength={ INPUT_MAXLENGTH }
                value={ configuration.description }
                onChange={ (e) => onConfigurationChange(e, configuration) }
            />
            <small>max { INPUT_MAXLENGTH } characters</small>
        </li>
    );

    return (
        <div className="row">
            <div className="col">
                <div className="mb-3" key="configurations">
                    {
                        component.configurationSections.length > 0 &&
                        <div className="mb-3" key="descriptions">
                            {
                                component.configurationSections
                                    .map(displayConfigurationSection)
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ConfigurationsTab;
