import React, { useContext } from "react";
import { CURRENT_USER } from "../../_common/context";
import Disconnect from "../../_common/assets/images/Disconnect";
import { useNavigate } from "react-router-dom";
import { signOut } from "../service/userApiCalls";
import { EMPTY_USER_STATE } from "../constants/userStatus";
import { LOGIN_ROUTE } from "../../_common/constants/routes";

const LogoutButton = () =>
{
    const navigate = useNavigate();
    const { setUser } = useContext(CURRENT_USER);
    const disconnect = () => signOut().then(() => setUser(EMPTY_USER_STATE))
        .then(() => navigate(LOGIN_ROUTE));

    return (
        <button className="btn disconnect-btn nav-link" onClick={ disconnect }>
            Disconnect <span><Disconnect /></span>
        </button>
    );
};

export default LogoutButton;
