import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import icons from "../assets/icons";

const Icon = ({ name, color, size = "sm", ...props }) =>
{
    if (icons[name]) {
        return (
            <FontAwesomeIcon
                icon={ icons[name] }
                color={ color }
                size={ size }
                fixedWidth
                { ...props }
            />
        );
    }

    throw new Error(
        `No icon found for icon: ${name}. 
        You should import and add the icon to the icons list.`
    );
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.oneOf(["xs", "lg", "sm"])
};

export default Icon;
