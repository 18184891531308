import React, { useContext, useEffect, useState, useMemo } from "react";
import Card from "./Card";
import {
    fetchApplications,
    deleteApplication
} from "../../application/clients/applicationClient";
import { APPLICATION } from "../../_common/constants/routes";
import { Link } from "react-router-dom";
import Icon from "../../_common/components/Icon";
import ConfirmModal from "../../_common/notifications/ConfirmModal";
import "./dashboard.sass";
import MagicWand from "../../_common/assets/images/MagicWand";
import {
    catchUnauthorized,
    createRefsById
} from "../../_common/helper/functions";
import { CURRENT_USER } from "../../_common/context";

const ApplicationList = () =>
{
    const { setUser } = useContext(CURRENT_USER);
    const [applications, setApplications] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [dropdownId, setDropdownId] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalId, setModalId] = useState();

    useEffect(
        () => {
            loadApplications().catch((e) => catchUnauthorized(e, setUser));
        },
        []
    );

    const refsById = useMemo(
        () => {
            return createRefsById(applications);
        },
        [applications]
    );

    const handleClickOutside = (event) => refsById[dropdownId]?.current &&
        !refsById[dropdownId].current.contains(event.target) &&
        setDropdownId(undefined);

    useEffect(
        () => {
            document.addEventListener("click", handleClickOutside, true);

            return () => document
                .removeEventListener("click", handleClickOutside, true);
        },
        [dropdownId]
    );

    const onDropdownActivationClick = (key) =>
        key === dropdownId ? setDropdownId(undefined) : setDropdownId(key);

    const displayCard = (item) => (
        <div key={ item.id }
            className="col-md-4 col-sm-12 card-container">
            <Card item={ item }>{ cardChildren(item) }</Card>
        </div>
    );

    const deleteApp = (id) => deleteApplication(id).then(loadApplications);

    const handleDelete = (applicationId) =>
    {
        setModalId(applicationId);
        setShowConfirmModal(true);
    };

    const loadApplications = () => fetchApplications()
        .then(applicationList => setApplications(applicationList))
        .catch(error => {
            setApplications([]);
            catchUnauthorized(error, setUser);
            setErrorMessage("Something went wrong");

            throw new Error(error);
        });

    const cardChildren = (item) => (
        <div className="dropdown-container">
            <div
                className="text-light dropdown-opener mx-2"
                onClick={ () => onDropdownActivationClick(item.id) }
                id={ item.id }
            >
                {
                    dropdownId && dropdownId === item.id ?
                        <Icon name="circleArrowUp" color="#A5C100" /> :
                        <Icon name="circleArrowDown" color="#A5C100" />
                }
            </div>
            {
                dropdownId && dropdownId === item.id ?
                    <div ref={ refsById[item.id] } className="dropdown-list" >
                        <Link key={ item.id } to={ `/application/${item.id}` }>
                            Edit <Icon name="edit" />
                        </Link>
                        <button
                            className="btn btn-action btn-disable"
                            onClick={ () => handleDelete(item.id) }
                        >
                            Delete <Icon name="trash"/>
                        </button>
                    </div> :
                    <></>
            }
        </div>
    );

    return (
        <>
            <div className="gallery">
                {
                    applications.length === 0 ?
                        <div className="no-app">
                            You have no applications on IBL
                        </div> :
                        <>
                            <div className="row">
                                {
                                    applications.length > 0 && !showAll ?
                                        applications.slice(0, 9)
                                            .map(displayCard) :
                                        applications.map(displayCard)
                                }
                            </div>
                            <ConfirmModal
                                show={ showConfirmModal }
                                setShow={ setShowConfirmModal }
                                message={
                                    <p className="modal-title">
                                        Are you sure you want to delete this
                                        app?
                                    </p>
                                }
                                handleConfirm={ () => deleteApp(modalId) }
                            />
                        </>
                }
            </div>

            <div className="btn-container">
                {
                    applications.length > 9 &&
                        <button className="btn button show-btn"
                            onClick={ () => setShowAll(!showAll) }>
                            { showAll ? "See less" : "See all" }
                        </button>
                }
                <a href={ APPLICATION } className="nav-link">
                    <button className="btn new-app-btn">
                        <MagicWand />
                        <span>Create a new App</span>
                    </button>
                </a>
            </div>
            { errorMessage && <div className="error">{ errorMessage }</div> }
        </>
    );
};

export default ApplicationList;
