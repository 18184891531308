import React, { useContext, useEffect, useMemo, useState } from "react";
import Card from "./Card";
import {
    disableComponent,
    enableComponent,
    fetchComponents,
    getComponentById,
    updateComponentStatus,
} from "../../component/clients/componentClient";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../_common/components/Icon";
import DisableIcon from "../../_common/assets/images/DisableIcon";
import EnableIcon from "../../_common/assets/images/EnableIcon";
import {
    catchUnauthorized,
    createRefsById
} from "../../_common/helper/functions";
import { CURRENT_USER } from "../../_common/context";
import PublishModal from "../../_common/notifications/PublishModal";
import { ethers } from "ethers";
import feLibrary from "../../_common/services/FrontEndLibrary";

let intervalId = null;

const ComponentList = () =>
{
    const { setUser } = useContext(CURRENT_USER);
    const [components, setComponents] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [dropdownId, setDropdownId] = useState();
    const [publishedComponent, setPublishedComponent] = useState(null);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [idToUpdate, setIdToUpdate] = useState();

    const location = useLocation();

    const refsById = useMemo(
        () => createRefsById(components),
        [components]
    );

    const handleClickOutside = (event) => refsById[dropdownId]?.current &&
        !refsById[dropdownId].current.contains(event.target) &&
        setDropdownId(undefined);

    useEffect(
        () => {
            location.state?.componentId &&
                setIdToUpdate(location.state.componentId);
        },
        [location]
    );

    useEffect(
        () => {
            if (idToUpdate && !intervalId) {
                intervalId = setInterval(
                    () => getNewStatus(idToUpdate), 5000
                );
            }
            if (!idToUpdate && !!intervalId){
                clearInterval(intervalId);
                intervalId = null;
            }

            return () => { clearInterval(intervalId); };
        },
        [idToUpdate]
    );

    useEffect(
        () => document.addEventListener("click", handleClickOutside, true),
        [dropdownId]
    );

    useEffect(() => { loadAndSortComponents(); }, [publishedComponent]);

    const getNewStatus = (componentId) => getComponentById(componentId)
        .then(component => {
            if (component.publishmentStatus === "PUBLISHED") {
                loadAndSortComponents();
                setIdToUpdate("");
            }
        });

    const unPublishMissingComponents = (componentList) =>
        componentList.filter(comp => comp.publishmentStatus === "PUBLISHED")
            .forEach(component => feLibrary.getComponent(component.componentId)
                .then((contractComponent) => {
                    if (!contractComponent.id) {
                        updateComponentStatus(
                            component.componentId,
                            "NOT_PUBLISHED"
                        );
                    }
                })
            );

    const loadAndSortComponents = () =>
    {
        fetchComponents()
            .then(componentList => {
                setComponents(componentList.sort((a, b) =>
                    a.status < b.status ? 1 : (a.status === b.status ? 0 : -1)
                ));
                //TODO: remove this when deployed to mainnet
                unPublishMissingComponents(componentList);
            })
            .catch((error) => {
                setComponents([]);
                catchUnauthorized(error, setUser);
            });
    };

    const onClick = (key) => setDropdownId(key === dropdownId ? undefined: key);

    const handleDisable = (id) =>
    {
        setDropdownId(undefined);
        disableComponent(id).then(loadAndSortComponents)
            .catch((e) => catchUnauthorized(e, setUser));
    };

    const handleEnable = (id) =>
    {
        setDropdownId(undefined);
        enableComponent(id).then(loadAndSortComponents)
            .catch((e) => catchUnauthorized(e, setUser));
    };

    const handlePublish = (id) =>
    {
        const component =
            components.filter(component => component.componentId === id)[0];
        let owners = [];
        let percentages = [];
        component.owners.forEach(owner => {
            owners.push(owner.walletAddress);
            percentages.push(ethers.utils.parseEther(owner.quota.toString()));
        });

        setPublishedComponent({
            id: component.componentId,
            displayName: component.displayName,
            owners: owners,
            percentages: percentages
        });
        setDropdownId(undefined);
        setShowPublishModal(true);
    };

    const setPrice = (value, priceType) => {
        const newComponent = { ...publishedComponent };

        if (priceType === "runPrice") {
            newComponent.runPrice = ethers.utils.parseUnits(value, "ether");
            newComponent.downloadPrice =
                newComponent.downloadPrice ||
                ethers.utils.parseUnits("0", "ether");
        } else {
            newComponent.runPrice =
                newComponent.runPrice || ethers.utils.parseUnits("0", "ether");
            newComponent.downloadPrice =
                ethers.utils.parseUnits(value, "ether");
        }

        setPublishedComponent(newComponent);
    };

    const displayCard = (item) => (
        <div key={item.id} className="col-md-4 card-container">
            <Card item={ item } >{ componentMenuDropdown(item) }</Card>
        </div>
    );

    const generateChangeStatusButton = (action, id, onClickAction) =>
    {
        const Icon = action === "Disable" ? DisableIcon : EnableIcon;

        return (
            <button
                className={ "btn btn-action btn-" + action.toLowerCase() }
                onClick={ () => onClickAction(id) }
            >
                { action } <Icon />
            </button>
        );
    };

    const getChangeStatusButton = (component) =>
    {
        if (component.publishmentStatus === "PUBLISHED") {
            if (component.canBeDeleted && component.status === "ENABLED") {
                return generateChangeStatusButton(
                    "Disable", component.componentId, handleDisable
                );
            } else if (component.status === "DISABLED") {
                return generateChangeStatusButton(
                    "Enable", component.componentId, handleEnable
                );
            }
        } else if (component.status !== "NOT_VALID") {
            return generateChangeStatusButton(
                "Publish", component.componentId, handlePublish
            );
        }
    };

    const onCloseModal = () => {
        setPublishedComponent(null);
        setShowPublishModal(false);
    };

    const componentMenuDropdown = (item) => (
        <div className="dropdown-container">
            <div
                className="dropdown-opener"
                id={ item.id }
                onClick={ () => onClick(item.id) }
            >
                {
                    dropdownId && dropdownId === item.id ?
                        <Icon name="circleArrowUp" color="#A5C100" /> :
                        <Icon name="circleArrowDown" color="#A5C100" />
                }
            </div>
            {
                dropdownId && dropdownId === item.id &&
                    <div
                        ref={ refsById[item.id] }
                        className="dropdown-list"
                    >
                        <Link
                            key={ item.id }
                            to={ `/component/${item.componentId}` }
                        >
                            Edit <Icon name="edit" />
                        </Link>
                        { getChangeStatusButton(item) }
                    </div>
            }
        </div>
    );

    return (
        <div className="gallery">
            <div className="row">
                {
                    components.length > 0 && !showAll ?
                        components.slice(0, 9).map(displayCard) :
                        components.map(displayCard)
                }
            </div>
            <div className="btn-container">
                {
                    components.length > 9 &&
                        <button className="btn button show-btn"
                            onClick={ () => setShowAll(!showAll) }>
                            { showAll ? "See less" : "See all" }
                        </button>
                }
            </div>
            {
                components.length === 0 &&
                    <div>You have no components on IBL</div>
            }
            {
                publishedComponent &&
                <PublishModal
                    show={ showPublishModal }
                    setShow={ setShowPublishModal }
                    component={ publishedComponent }
                    onCloseModal={ onCloseModal }
                    setPrice={ setPrice }
                    showSetFees={ true }
                    setIdToUpdate={ setIdToUpdate }
                />
            }
        </div>
    );
};

export default ComponentList;
