export const TAB_GENERAL_DETAILS = {
    label: "General details",
    title: "Edit your component meta-data here",
    value: "1"
};

export const TAB_INPUT_OUTPUT = {
    label: "Input & Output",
    title: "Define input & output for a better experience",
    value: "2"
};

export const TAB_CONFIGURATIONS = {
    label: "Configurations",
    title: "Help your users have a better experience interacting with your\n" +
        "component by adding a description to your Configuration Sections\n" +
        "and Configurations. This step is not mandatory",
    value: "3"
};

export const TAB_FEES = {
    label: "Fees",
    title: "Define your fees",
    value: "4"
};
