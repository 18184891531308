import "../style/ide.sass";
import React, { useEffect, useState } from "react";
import { ioTypesCanBeConverted } from "../common/helperFunctions";

const Graphics = (props) =>
{
    const { dropZones, onBoardComponents } = props;
    const [connectionList, setConnectionList] = useState([]);

    useEffect(
        () => setConnectionList(
            Object.values(onBoardComponents)
                .flatMap(component => component.incomingConnections)
                .map(createConnectionInfo)
        ),
        [props]
    );

    const createConnectionInfo = (connection) => ({
        data: connection,
        hotspotStart: document.getElementById(connection.start.node),
        hotspotEnd: document.getElementById(connection.end.node)
    });

    const renderConnectionLine = (connection) =>
    {
        const startPoint = onBoardComponents[connection.data.start.gridItem];
        const endPoint = onBoardComponents[connection.data.end.gridItem];
        if (!(startPoint && endPoint)) {
            return;
        }
        const start = connection.hotspotStart;
        const end = connection.hotspotEnd;
        const svgBox = document.getElementById("svg").getBoundingClientRect();
        const key = connection.data.start.node + "_" + connection.data.end.node;

        if (start && end) {
            const [startX, startY] = getPointCoordinates(start, svgBox);
            const [endX, endY] = getPointCoordinates(end, svgBox);

            return (
                <line
                    key={ key }
                    x1={ startX } y1={ startY } x2={ endX } y2={ endY }
                    markerEnd="url(#arrow)"
                    className={ getConnectionClass(connection.data) }
                    onClick={ () => props.customizeConnection(connection.data) }
                />
            );
        } else if (start) {
            return drawLineToTheEdge(connection.data, start, "start", svgBox);
        } else if (end) {
            return drawLineToTheEdge(connection.data, end, "end", svgBox);
        }
    };

    const drawLineToTheEdge = (connection, endPoint, endpointName, svgBox) =>
    {
        const node = connection[endpointName].node.split("_").pop();
        let startX, startY, endX, endY;

        if (endpointName === "start") {
            [startX, startY] = getPointCoordinates(endPoint, svgBox);
            [endX, endY] = getEdgeCoordinates(node, startX, startY, svgBox);
        } else {
            [endX, endY] = getPointCoordinates(endPoint, svgBox);
            [startX, startY] = getEdgeCoordinates(node, endX, endY, svgBox);
        }

        return (
            <line
                key={ connection.start.node + "_" + connection.end.node }
                x1={ startX } y1={ startY } x2={ endX } y2={ endY }
                markerEnd="url(#arrow)"
                className={ getConnectionClass(connection) }
                onClick={ () => props.customizeConnection(connection) }
            />
        );
    };

    const getEdgeCoordinates = (node, endPointX, endPointY, svgBox) =>
    {
        switch (node) {
            case "top":
                return [endPointX, 0];
            case "right":
                return [svgBox.right - svgBox.left, endPointY];
            case "bottom":
                return [endPointX, svgBox.bottom - svgBox.top];
            case "left":
                return [0, endPointY];
        }
    };

    const getPointCoordinates = (hotspot, svgBox) =>
    {
        const hotspotBox = hotspot.getBoundingClientRect();
        const X = (hotspotBox.left + hotspotBox.right) / 2 - svgBox.left;
        const Y = (hotspotBox.top + hotspotBox.bottom) / 2 - svgBox.top;

        return [X, Y];
    };

    const getConnectionClass = (connection) =>
    {
        const endComponent = onBoardComponents[connection.end.gridItem];

        const suffix = !hasMatchingInputMapping(endComponent) ? "error" :
            (endComponent.hasInputMappingsCompleted ? "" : "incomplete");

        return "connection-line " + suffix;
    };

    const hasMatchingInputMapping = (component) =>
    {
        const outputTypes = Object.values(props.getUpstreamOutputs(component))
            .reduce(
                (acc, outputs) => [
                    ...acc,
                    Object.values(outputs).map((output) => output.type)
                ],
                [])
            .flat(1);

        const inputs = dropZones[component.dropZoneId][0].input;

        return inputs.every((input) => outputTypes.some(
            (outputType) => ioTypesCanBeConverted(outputType, input.type))
        );
    };

    return (
        <svg id="svg">
            <defs>
                <marker
                    id="arrow"
                    viewBox="0 -5 10 10"
                    refX="15" refY="0"
                    markerWidth="4" markerHeight="4"
                    orient="auto"
                >
                    <path
                        d="M0,-5L10,0L0,5"
                        className="connection-marker arrowHead"
                    >
                    </path>
                </marker>
            </defs>
            { connectionList.map(renderConnectionLine) }
        </svg>
    );
};

export default Graphics;
