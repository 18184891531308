import React, { useState } from "react";
import { ethers } from "ethers";

const FeesTab = ({ fees, onFeeChange }) =>
{
    const [showFeesMessage, setShowFeesMessage] = useState(false);
    const downloadFeeHint = "you will receive this every time " +
        "someone downloads an application with your component in it";
    const runFeeHint = "you will receive this when an application with " +
        "your component in it is published";

    const handleChangedFee = (e, type) => {
        setShowFeesMessage(true);
        onFeeChange(type, ethers.utils.parseUnits(e.target.value, "ether"));
    };

    const displayFee = (type, price) => (
        <div className="mb-3" key={ type }>
            <label className="form-label">
                { type === "runPrice" ? "Run price" : "Download price" }
            </label>
            <div className="input-with-unit">
                <input
                    defaultValue={ price ? ethers.utils.formatEther(price) : ""}
                    onChange={ (e) => handleChangedFee(e, type) }
                    type="number"
                    step="any"
                    min="0"
                    className="form-control"
                    placeholder={ "Your " + type }
                />
                <span className="unit">ETH</span>
            </div>
            <span>
                { type === "runPrice" ? runFeeHint : downloadFeeHint }
            </span>
        </div>
    );

    return (
        <div className="row">
            <div className="col">
                {
                    Object.entries(fees)
                        .map(([type, price]) => displayFee(type, price))
                }
            </div>
            {
                showFeesMessage &&
                <div className="error-message">
                    To save the changes you will have to sign
                    the transaction with Metamask!
                </div>
            }
        </div>
    );
};

export default FeesTab;
