import React from "react";
import {
    INPUT_MAXLENGTH,
    TEXTAREA_MAXLENGTH
} from "../constants/maxInputLength";
import { displayError } from "../../_common/helper/functions";

const GeneralDetailsTab = ({
    component,
    errorsList,
    onDisplayNameChange,
    onDescriptionChange,
}) => {
    const getClassName = (type) =>
        errorsList[type].length > 0 ? "not-valid" : "";

    return (
        <div className="row">
            <div className="col">
                <div className="mb-3" key="displayName">
                    <label className="form-label">
                        Component name
                    </label>
                    <input
                        type="text"
                        className={
                            "form-control " +
                            getClassName("displayName")
                        }
                        placeholder="Component name"
                        value={component.displayName}
                        maxLength={INPUT_MAXLENGTH}
                        onChange={onDisplayNameChange}
                    />
                    { errorsList.displayName.map(displayError) }
                    <small>max {INPUT_MAXLENGTH} characters</small>
                </div>
                <div className="mb-3" key="description">
                    <label className="form-label">
                        Component description
                    </label>
                    <textarea
                        className={
                            "form-control " +
                            getClassName("description")
                        }
                        value={component.description}
                        placeholder="Description..."
                        rows="3"
                        maxLength={TEXTAREA_MAXLENGTH}
                        onChange={onDescriptionChange}
                    />
                    { errorsList.description.map(displayError) }
                    <small>max {TEXTAREA_MAXLENGTH} characters</small>
                </div>
            </div>
            {/*<div className="col-md-6">*/}
            {/*    <div className="mb-3" key="tags">*/}
            {/*        <label className="form-label">*/}
            {/*            Tags**/}
            {/*        </label>*/}
            {/*        <div*/}
            {/*            className={ getClassName("tags") }*/}
            {/*        >*/}
            {/*            <TagsAutocomplete*/}
            {/*                tagIds={ component.tagIds }*/}
            {/*                updateTagIds={ updateTagIds }*/}
            {/*                newTags={ newTags }*/}
            {/*                setNewTags={ setNewTags }*/}
            {/*                clearTags={ clearTags }>*/}
            {/*            </TagsAutocomplete>*/}
            {/*        </div>*/}
            {/*        { errorsList.tags.map(displayError) }*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default GeneralDetailsTab;
