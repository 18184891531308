import React, { useContext, useState, useEffect } from "react";
import "./account.sass";
import { CURRENT_USER } from "../../_common/context";
import { getUser, updateUser } from "../service/userApiCalls";
import { useNavigate } from "react-router-dom";
import { DASHBOARD, LOGIN_ROUTE } from "../../_common/constants/routes";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";
import { isNotEmpty } from "../../_common/helper/checks";
import Icon from "../../_common/components/Icon";
import icons from "../../_common/assets/icons";
import { catchUnauthorized } from "../../_common/helper/functions";
import { BadRequest } from "../errors/BadRequest";
import { Unauthorized } from "../errors/Unauthorized";
import { UNKNOWN_ERROR } from "../../_common/constants/errors";

const UNEXPECTED_ERROR = "An unexpected error happened.";
const INVALID_PACKAGES = "There are invalid packages in the list.";
const INVALID_EMAIL_ADDRESS = "Invalid email address";
const INVALID_PACKAGE = "Invalid package name";
const EMAIL_REGEXP =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const Account = (props) =>
{
    const { user, setUser } = useContext(CURRENT_USER);
    const navigate = useNavigate();
    !user.username && navigate(LOGIN_ROUTE);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [declaredPackages, setDeclaredPackages] = useState([]);
    const [initialDeclaredPackages, setInitialDeclaredPackages] = useState([]);
    const [verifying, setVerifying] = useState(false);
    const [globalErrors, setGlobalErrors] = useState([]);
    const [errors, setErrors] = useState(getErrorsInitialState());

    const loadUser = () => getUser()
        .then(user => {
            setName(user.name || "");
            setEmail(user.email || "");
            setDeclaredPackages([]);
            setInitialDeclaredPackages(user.declaredPackages || []);
        })
        .catch((e) => catchUnauthorized(e, setUser));

    useEffect(() => { loadUser(); }, [user.username]);
    useEffect(
        () => { errors.email.length && setErrors({ ...errors, email: [] }); },
        [email]
    );

    const handleSubmit = (e) =>
    {
        e.preventDefault();
        if (validateForm()) {
            setVerifying(true);
            const dto = {
                name: name,
                email: email,
                declaredPackages: declaredPackages.filter(isNotEmpty)
                    .concat(initialDeclaredPackages.map(
                        declaredPackage => declaredPackage.packageName
                    ))
            };

            updateUser(dto).then(() => handleSuccessResponse())
                .catch((e) => {
                    if (e instanceof BadRequest) {
                        e.error.then((error) =>
                            error.violations && Object.keys(error.violations)
                                .forEach((element) => {
                                    const field = element.split(".").pop();
                                    const newErrors =
                                        getErrorsInitialState();
                                    newErrors[field] = [UNKNOWN_ERROR];
                                    setErrors(newErrors);
                                })
                        );
                    }
                    handleErrorResponse(e, setUser);
                })
                .finally(() => setVerifying(false));
        }
    };

    const validateForm = () =>
    {
        let isValid = true;
        const newErrors = getErrorsInitialState();
        const invalidPackage = declaredPackages.reduce(
            (result, packageName, index) => {
                if (/\s/g.test(packageName)) {
                    result[index] = [INVALID_PACKAGE];
                }

                return result;
            },
            {}
        );

        if (Object.keys(invalidPackage).length > 0) {
            newErrors.declaredPackages = invalidPackage;
            isValid = false;
        }
        if (email && !EMAIL_REGEXP.test(email)) {
            newErrors.email = [INVALID_EMAIL_ADDRESS];
            isValid = false;
        }
        setErrors(newErrors);

        return isValid;
    };

    const handleSuccessResponse = () => loadUser().then(() => {
        props.modal && props.modal.setOpen(false);
        setGlobalErrors([]);
        navigate(DASHBOARD);
    });

    const handleErrorResponse = (errorResponse) =>
    {
        if (errorResponse instanceof BadRequest) {
            errorResponse.error.then(error => {
                if (error.title && error.title === "User006") {
                    const packageErrors = error.params.notFoundPackages.reduce(
                        (result, notFound) => {
                            result[declaredPackages.indexOf(notFound)] =
                                [INVALID_PACKAGE];

                            return result;
                        },
                        {}
                    );
                    setErrors({ ...errors, declaredPackages: packageErrors });
                    setGlobalErrors([INVALID_PACKAGES]);
                } else {
                    setGlobalErrors([UNEXPECTED_ERROR]);
                }
            });
        } else if (errorResponse instanceof Unauthorized) {
            catchUnauthorized(errorResponse, setUser);
        } else {
            setGlobalErrors([UNEXPECTED_ERROR]);
        }
    };

    const handleUpdateDeclaredPackages = (value, index) =>
    {
        const currentDeclaringPackages = [...declaredPackages];
        if (currentDeclaringPackages[index] !== undefined) {
            currentDeclaringPackages[index] = value;
            setDeclaredPackages([...currentDeclaringPackages]);
            const declaredPackageError = errors.declaredPackages[index];
            declaredPackageError && declaredPackageError.length && setErrors({
                ...errors,
                declaredPackages: { ...declaredPackages, [index]: [] }
            });
        }
    };

    const addEmptyDeclaredPackage = () =>
    {
        declaredPackages.unshift("");
        setDeclaredPackages([...declaredPackages]);
    };

    const generateClassName = (field, declaredPackageIndex = null) =>
    {
        const fieldHasError = (declaredPackageIndex === null) ?
            errors[field] && errors[field].length > 0 :
            Object.hasOwn(errors.declaredPackages, declaredPackageIndex) &&
                errors.declaredPackages[declaredPackageIndex].length > 0;

        return "form-control " + (fieldHasError ? "not-valid" : "");
    };

    const removeDeclaredPackage = (index) =>
        setDeclaredPackages(declaredPackages.filter((i) => index !== i));

    const renderDeclaredPackages = () =>
        declaredPackages.map((packageName, index) => (
            <div className="editable-packages" key={ index }>
                <input
                    type="text"
                    className={ generateClassName("declaredPackages", index) }
                    value={ packageName }
                    onChange={
                        e => handleUpdateDeclaredPackages(e.target.value, index)
                    }
                />
                <button
                    className="btn button remove-btn"
                    type={ "button" }
                    onClick={ () => removeDeclaredPackage(packageName, index) }
                >
                    -
                </button>
            </div>
        ));

    const declaredPackagesTooltip = (status) =>
    {
        switch(status) {
            case "PENDING":
                return "Import Pending";
            case "IMPORTED":
                return "Imported";
            case "ERROR":
                return "Import failed";
            case "NOT_OWNED_BY_USER":
                return "Import not allowed";
            case "MISSING_USED_COMPONENTS":
                return "Used component/theme missing";
            case "UPDATE_ERROR":
                return "Generic error happened during update";
        }
    };

    const renderInitialDeclaredPackages = () =>
        initialDeclaredPackages.length === 0 ?
            <p className="disabled-grey">
                You have no packages declared yet
            </p> :
            initialDeclaredPackages.map((initialPack, index) => (
                <div key={ index } className="input-container">
                    <input
                        type="text"
                        className={
                            "form-control " +
                                (initialPack.status ?? "").toLocaleLowerCase()
                        }
                        value={ initialPack.packageName }
                        key={ index }
                        disabled={ true }
                    />
                    {
                        icons[initialPack.status.toLowerCase()] &&
                            <Icon name={ initialPack.status.toLowerCase() }
                                className={ initialPack.status.toLowerCase() }
                                data-toggle="tooltip" data-placement="right"
                                title={
                                    declaredPackagesTooltip(initialPack.status)
                                }
                            />
                    }
                </div>
            ));

    return (
        <div className="d-flex justify-content-center account-container">
            <form className="account-form" onSubmit={ e => handleSubmit(e) }>
                <div className="form-heading">
                    <h2>Complete your account</h2>
                    <p className="m-0">
                        These account details do not interfere with your
                        MetaMask wallet, they are useful for securing your IBL
                        account.
                    </p>
                    <p>
                        You can update the information from your
                        account settings whenever you want. If you are a
                        contributor, you can import your own component
                        packages in IBL.
                    </p>
                </div>
                <div className="row">
                    <div className="col-md-5 account-details">
                        <div className="mb-3" key="name">
                            <label className="form-label">User name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Type here"
                                onChange={ e => setName(e.target.value) }
                                key={"name"}
                                value={ name }
                            />
                        </div>
                        <div className="mb-3" key="email">
                            <label className="form-label">Email address</label>
                            <input
                                type="text"
                                className={ generateClassName("email") }
                                placeholder="Type here"
                                onChange={ e => setEmail(e.target.value) }
                                key={"email"}
                                value={ email }
                            />
                        </div>
                    </div>
                    <div className="offset-md-1 col-md-6">
                        <div className="import-heading">
                            <p>Import NPM packages</p>
                            {
                                declaredPackages.length < 20 &&
                                    <button className="btn button"
                                        type="button"
                                        onClick={ addEmptyDeclaredPackage }
                                    >
                                        +
                                    </button>
                            }
                        </div>

                        <Backdrop open={ verifying }>
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                        <p className="packages-instruction">
                            Add up to 20 NPM packages / update
                        </p>
                        <div className="packages-container" >
                            { displayErrors(globalErrors) }
                            <div className="mb-3" key="declaredPackages">
                                { renderDeclaredPackages() }
                            </div>
                            <div className="mb-3 initial-packages">
                                { renderInitialDeclaredPackages() }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    { props.children }
                    <button type="submit" className="btn button">Save</button>
                </div>
            </form>
        </div>
    );
};

const displayErrors = (errors) => errors.length === 0 ? <></> :
    <div className="mb-3 error" key={"failedPackages"}>
        {
            errors.map((error, index) => (
                <label className={ "error" } key={ index }>{ error }</label>
            ))
        }
    </div>;

const getErrorsInitialState = () => ({ declaredPackages: {}, email: [] });

export default Account;
