import React, { useState, useEffect } from "react";
import "../style/selectField.sass";

const SelectField = (props) =>
{
    const defaultValue = props.defaultValue?.map(element => element.value);
    const [selected, setSelected] = useState(defaultValue || []);
    const options = props.configuration.options;

    useEffect(
        () => {
            const chosenInputs = options.filter(
                option => selected.includes(option.value)
            );
            props.handleConfigurationChange(chosenInputs, props.configuration);
        },
        [selected]
    );

    const handleSelectChange = (event) =>
    {
        const selectedOption = options.find(
            option => event.target.value === option.value
        );
        setSelected(selectedOption ? [selectedOption.value] : []);
    };

    const handleCheckboxChange = (event) => setSelected(
        event.target.checked ?
            [event.target.value, ...selected] :
            selected.filter(option => option !== event.target.value)
    );

    const renderCheckboxes = () => options.map((option) =>
        <div key={ option.value } className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                defaultValue={ option.value }
                checked={ selected.includes(option.value) }
                onChange={ handleCheckboxChange }
            />
            <label className="form-check-label">{ option.title }</label>
        </div>
    );

    const renderSelect = () =>
        <select
            onChange={ handleSelectChange }
            className="form-control"
            name="inputs"
            defaultValue={ selected[0] }
        >
            <option value="">Select</option>
            {
                options.map((option) => (
                    <option key={ option.value } value={ option.value }>
                        { option.title }
                    </option>
                ))
            }
        </select>;

    return (
        <div className="form-group">
            <label className="form-label">Customize</label>
            {
                props.configuration.multipleOptions ?
                    renderCheckboxes() : renderSelect()
            }
        </div>
    );
};

export default SelectField;
