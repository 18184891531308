import React, { useState, useMemo, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home/components/Home";
import { CURRENT_USER } from "./_common/context";
import "bootstrap/dist/css/bootstrap.css";
import {
    ACCOUNT_ROUTE,
    COMPONENT_ROUTE,
    APPLICATION,
    DASHBOARD,
    HOME_ROUTE,
    IDE_APPLICATION,
    DASHBOARD_TAB,
    LOGIN_ROUTE,
    APPLICATION_EDIT,
    WIZARD
} from "./_common/constants/routes";
import { EMPTY_USER_STATE } from "./user/constants/userStatus";
import {
    addWalletListener,
    addNetworkListener,
    switchToDefaultNetwork
} from "./user/service/clients/metamaskClient";
import { PageNotFound } from "./_common/components/PageNotFound";
import Ide from "./ide/components/Ide";
import ApplicationForm from "./application/components/ApplicationForm";
import Nav from "./_common/components/nav/Nav";
import Account from "./user/components/Account";
import DashboardPage from "./dashboard/components/DashboardPage";
import "./index.sass";
import EditComponent from "./component/components/EditComponent";
import LoginPage from "./user/components/LoginPage";
import WizardLoading from "./ide/components/WizardLoading";
import { getUser, updateUserStatus } from "./user/service/userApiCalls";
import { Unauthorized } from "./user/errors/Unauthorized";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const USER_STORAGE_KEY = "iblUser";

let interval = null;
const SESSION_CHECK_TIMEOUT = 5000;

const App = () =>
{
    const [user, setUser] = useState(EMPTY_USER_STATE);
    const [walletChanged, setWalletChanged] = useState(false);
    const currentUser = useMemo(() => ({ user, setUser }), [user, setUser]);
    const [networkChanged, setNetworkChanged] = useState(false);

    addWalletListener(setWalletChanged);

    addNetworkListener(setNetworkChanged);

    useEffect(
        () => {
            switchToDefaultNetwork();
            const user = localStorage.getItem(USER_STORAGE_KEY);
            user && setUser(JSON.parse(user));
            validateUserSession();
        },
        []
    );

    useEffect(
        () => {
            walletChanged === true && setUserEmptyState();
        },
        [walletChanged]
    );

    useEffect(
        () => {
            networkChanged === true && setNetworkChanged(false);
        },
        [networkChanged]
    );

    useEffect(
        () => {
            localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
            runSessionCheckCron();
        },
        [user]
    );

    const runSessionCheckCron = () =>
    {
        if (user.username && !interval) {
            interval = setInterval(
                () => validateUserSession(),
                SESSION_CHECK_TIMEOUT
            );
        }
        !user.username && interval && clearInterval(interval);
    };

    const setUserEmptyState = () => setUser(EMPTY_USER_STATE);

    const validateUserSession = () => getUser()
        .then((currentUser) => updateUserStatus(user, currentUser, setUser))
        .catch(error => error instanceof Unauthorized && setUserEmptyState());

    return (
        <Router>
            <CURRENT_USER.Provider value={ currentUser }>
                { user.username && <Nav /> }
                <div className="app-container container-fluid">
                    { routes() }
                </div>
            </CURRENT_USER.Provider>
        </Router>
    );
};

const routes = () => (
    <>
        <Routes>
            <Route exact path={ HOME_ROUTE } element={ <Home /> } />
            <Route exact path={ LOGIN_ROUTE } element={ <LoginPage /> } />
            <Route exact path={ DASHBOARD } element={ <DashboardPage /> } />
            <Route exact path={ DASHBOARD_TAB } element={ <DashboardPage /> } />
            <Route exact path={ APPLICATION } element={ <ApplicationForm /> } />
            <Route exact path={ APPLICATION_EDIT }
                element={ <ApplicationForm /> } />
            <Route exact path={ IDE_APPLICATION } element={ <Ide /> }/>
            <Route exact path={ ACCOUNT_ROUTE } element={ <Account /> } />
            <Route exact path={ COMPONENT_ROUTE }
                element={ <EditComponent /> } />
            <Route exact path={ WIZARD } element={ <WizardLoading /> } />
            <Route path="*" element={ <PageNotFound /> }/>
        </Routes>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            limit={1}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
    </>
);

export default App;
