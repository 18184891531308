import React from "react";

const DisableIcon = () => 
    (
        <svg xmlns="http://www.w3.org/2000/svg" 
            width="15" height="15" viewBox="0 0 15 15">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_71" data-name="Rectangle 71" width="15" 
                        height="15" transform="translate(1159 434)" 
                        fill="#ff4500"/>
                </clipPath>
            </defs>
            <g id="Mask_Group_4" data-name="Mask Group 4" 
                transform="translate(-1159 -434)" clipPath="url(#clip-path)">
                <path id="disabled" d="M7.5,0A7.5,7.5,0,1,0,15,7.5,7.509,
                    7.509,0,0,0,7.5,0ZM1.875,7.5A5.588,5.588,0,0,1,
                    2.92,4.246l7.834,7.834A5.616,5.616,0,0,1,1.875,
                    7.5Zm10.2,3.254L4.246,2.92a5.616,5.616,0,0,1,7.834,7.834Z" 
                transform="translate(1159 434)" fill="#ff4500"/>
            </g>
        </svg>
    );

export default DisableIcon;