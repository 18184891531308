import React, { useContext, useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import Claim from "./Claim";
import feLibrary from "../../_common/services/FrontEndLibrary";
import { CURRENT_USER } from "../../_common/context";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabContent from "./TabContent";

const ClaimAndStake = () =>
{
    const { user } = useContext(CURRENT_USER);
    const [protocolFees, setProtocolFees] = useState(BigNumber.from("0"));
    const [rewards, setRewards] = useState(BigNumber.from("0"));
    const [totalStaked, setTotalStaked] = useState(BigNumber.from("0"));
    const [iblBalance, setIblBalance] = useState(BigNumber.from("0"));
    const [stake, setStake] = useState(BigNumber.from("0"));
    const [unstake, setUnstake] = useState(BigNumber.from("0"));
    const [tab, setTab] = useState("STAKE");
    const [stateChanged, setStateChanged] = useState(false);
    const [allowance, setAllowance] = useState(BigNumber.from("0"));

    useEffect(
        () => {
            setStateChanged(false);
            if (user.username) {
                feLibrary.getAccumulatedProtocolFee(user.username)
                    .then(setProtocolFees);
                feLibrary.getAccumulatedRewards(user.username)
                    .then(setRewards);
                feLibrary.getBalance(user.username).then(setIblBalance);
                feLibrary.getStakedAmount(user.username).then(setTotalStaked);
                feLibrary.getAllowance(user.username).then(setAllowance);
            }
        },
        [user.username, stateChanged]
    );

    const renderInput = (value, setValue) => (
        <input
            type="number"
            min="0"
            step="any"
            className="form-control col"
            placeholder="Enter amount here"
            value={ Number(ethers.utils.formatEther(value)) }
            onChange={
                (e) => setValue(ethers.utils.parseEther(e.target.value || "0"))
            }
        />
    );

    const handleChangeTab = (event, value) => setTab(value);

    return (
        <div className="content-right__sections">
            <div className="section claim-container padded">
                <Claim
                    title={ "Total protocol fees" }
                    value={ protocolFees }
                    currency={ "ETH" }
                    claim={ () => feLibrary.claimProtocol(user.username) }
                    resetState={ () => setStateChanged(true) }
                    buttonText={ "Claim protocol" }
                    iconName={ "protocol" }
                />
            </div>
            <div className="section claim-container padded">
                <Claim
                    title={ "Total rewards" }
                    value={ rewards.sub(totalStaked) }
                    currency={ "IBL" }
                    claim={ () => feLibrary.claimAllRewards(user.username) }
                    resetState={ () => setStateChanged(true) }
                    buttonText={ "Get rewards" }
                    iconName={ "star" }
                />
            </div>
            <div className="section staking">
                <TabContext value={ tab }>
                    <TabList onChange={ handleChangeTab }>
                        <Tab label="STAKE" value="STAKE"/>
                        <Tab label="UNSTAKE" value="UNSTAKE"/>
                    </TabList>
                    <TabPanel className="tab-panel" value="STAKE">
                        <TabContent
                            data={ [
                                {
                                    label: "Total staked amount",
                                    value: totalStaked
                                },
                                {
                                    label: "Tokens available in wallet",
                                    value: iblBalance
                                },
                            ] }
                            renderInput={ () => renderInput(stake, setStake) }
                            onMaxClick={ () => setStake(iblBalance) }
                            onActionClick={ () =>
                                feLibrary.stakeRewards(user.username, stake)
                            }
                            actionButtonText={ "Stake" }
                            resetState={ () => setStateChanged(true) }
                            noAllowance={ allowance.lt(stake) }
                        />
                    </TabPanel>
                    <TabPanel className="tab-panel" value="UNSTAKE">
                        <TabContent
                            data={ [
                                {
                                    label: "Available to unstake",
                                    value: totalStaked
                                }
                            ] }
                            renderInput={
                                () => renderInput(unstake, setUnstake)
                            }
                            onMaxClick={ () => setUnstake(totalStaked) }
                            onActionClick={
                                () => feLibrary.unstakeRewards(
                                    user.username, unstake
                                )
                            }
                            actionButtonText={ "Unstake" }
                            resetState={ () => setStateChanged(true) }
                        />
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    );
};

export default ClaimAndStake;