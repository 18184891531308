import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../style/wizardLoading.sass";
import ready from "../../_common/assets/images/okay.svg";
import stars from "../../_common/assets/images/meteorRain.gif";
import {
    deployPhase
} from "../../application/clients/applicationClient";
import { configurations } from "../../_common/configurations";

let intervalId;

const WizardLoading = () =>
{
    const { applicationId } = useParams();
    const [phase, setPhase] = useState("");
    const [applicationURL, setApplicationURL] = useState();

    useEffect(() => {
        intervalId = setInterval(() => getApplicationDeployPhase(), 5000);
    }, []);

    const getApplicationDeployPhase = () =>
    {
        deployPhase(applicationId).then((result) => {
            switch(result.phase) {
                case "DEPLOYED":
                    setPhase("deployed");
                    clearInterval(intervalId);
                    break;
                case "FAILED":
                    setPhase("failed");
                    clearInterval(intervalId);
                    break;
                default:
                    setPhase("running");
            }

            setApplicationURL(
                `https://${result.domain}${configurations.endUserAppDomain}`
            );
        });
    };

    return (
        <div className="wizard-container">
            {
                phase === "deployed" ?
                    <>
                        <img src={ ready } alt="ready" />
                        <h2>Wizard is ready</h2>
                        <p className="description">
                            You can access your application on this
                            &nbsp;
                            <a
                                href={ applicationURL }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                link
                            </a>
                        </p>
                    </> :
                    phase === "failed" ?
                        <h2>Something went wrong.</h2> :
                        <>
                            <img src={ stars } alt="ready" />
                            <h2>Preparing the IBL wizard</h2>
                        </>
            }
        </div>
    );
};

export default WizardLoading;
