import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    // addTag,
    getComponentById,
    updateComponent
} from "../clients/componentClient";
import "./editComponent.sass";
import { CURRENT_USER } from "../../_common/context";
import { LOGIN_ROUTE, DASHBOARD } from "../../_common/constants/routes";
import {
    GENERIC_ERROR,
    MANDATORY_ERROR,
    UNKNOWN_ERROR,
    GENERIC_INPUT_ERROR,
    REJECTED_PAYMENT
} from "../../_common/constants/errors";
import { PAYMENT_PROCESSING } from "../../_common/constants/notifications";
import ConfirmModal from "../../_common/notifications/ConfirmModal";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../_common/constants/messageStyles";
import {
    catchUnauthorized,
    removeErrorWhenTyping,
} from "../../_common/helper/functions";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import GeneralDetailsTab from "./GeneralDetailsTab";
import InputOutputTab from "./InputOutputTab";
import ConfigurationsTab from "./ConfigurationsTab";
import FeesTab from "./FeesTab";
import {
    TAB_GENERAL_DETAILS,
    TAB_INPUT_OUTPUT,
    TAB_CONFIGURATIONS,
    TAB_FEES
} from "../constants/tabs";
import { LEAVE_CONFIRM_MESSAGE } from "../constants/leaveConfirmMessage";
import { EMPTY_COMPONENT } from "../constants/emptyComponent";
import { emptyErrorState } from "../constants/emptyErrorState";
import TabPanel from "@mui/lab/TabPanel";
import ActionButtons from "./ActionButtons";
// import TagsAutocomplete from "./TagsAutocomplete";
import { BadRequest } from "../../user/errors/BadRequest";
import feLibrary  from "../../_common/services/FrontEndLibrary";
import _ from "lodash";
import PublishModal from "../../_common/notifications/PublishModal";
import { BigNumber, ethers } from "ethers";

const EditComponent = () =>
{
    const navigate = useNavigate();
    const { user, setUser } = useContext(CURRENT_USER);
    !user.username && navigate(LOGIN_ROUTE);
    const { id } = useParams();
    const [tab, setTab] = useState("1");
    const [component, setComponent] = useState(EMPTY_COMPONENT);
    const [isStateChanged, setStateChanged] = useState(false);
    // const [newTags, setNewTags] = useState([]);
    const [initialFees, setInitialFees] =
        useState({ runPrice: null, downloadPrice: null });
    const [changedFees, setChangedFees] =
        useState({ runPrice: null, downloadPrice: null });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [errorsList, setErrorsList] = useState(emptyErrorState());
    const [updatedFees, setUpdatedFees] = useState(false);

    const [publishedComponent, setPublishedComponent] = useState(null);
    const [showPublishModal, setShowPublishModal] = useState(false);

    useEffect(
        () => {
            getComponentById(id)
                .then(component => {
                    setComponent(component);
                    getFees(component);
                    resetErrors();
                })
                .catch((e) => catchUnauthorized(e, setUser));
        },
        [id]
    );

    useEffect(
        () => {
            if (component.id) {
                let owners = [];
                let percentages = [];
                component.owners.forEach(owner => {
                    owners.push(owner.walletAddress);
                    percentages.push(
                        ethers.utils.parseEther(owner.quota.toString())
                    );
                });

                setPublishedComponent({
                    id: component.componentId,
                    displayName: component.displayName,
                    runPrice: changedFees.runPrice,
                    downloadPrice: changedFees.downloadPrice,
                    owners: owners,
                    percentages: percentages
                });
            }
        },
        [changedFees]
    );

    useEffect(
        () => removeErrorWhenTyping(errorsList, setErrorsList, "displayName"),
        [component.displayName]
    );

    useEffect(
        () => removeErrorWhenTyping(errorsList, setErrorsList, "description"),
        [component.description]
    );

    const getFees = (component) => feLibrary.getComponent(component.componentId)
        .then((contractComponent) => {
            if (contractComponent.id) {
                const fees = {
                    runPrice: contractComponent.runPrice,
                    downloadPrice: contractComponent.downloadPrice
                };
                setInitialFees(fees);
                setChangedFees(fees);
            }
        })
        .catch(() => {
            const fees = {
                runPrice: BigNumber.from("0"),
                downloadPrice: BigNumber.from("0")
            };
            setInitialFees(fees);
            setChangedFees(fees);
        });

    const onPaymentInitialize = () =>
    {
        setUpdatedFees(true);
        toast.info(PAYMENT_PROCESSING, TOAST_SETTINGS);
        leavePage(DASHBOARD);
    };

    const updateComponentFee = () =>
    {
        if (_.isEqual(initialFees, changedFees)) {
            leavePage(DASHBOARD);
        } else {
            const changedComponent = {
                id: component.componentId,
                runPrice: changedFees.runPrice,
                downloadPrice: changedFees.downloadPrice
            };
            const downloadFeeDiff =
                changedFees.downloadPrice.sub(initialFees.downloadPrice);

            return feLibrary
                .setNewPrice(
                    changedComponent, downloadFeeDiff, onPaymentInitialize
                );
        }
    };

    // useEffect(
    //     () => removeErrorWhenTyping(errorsList, setErrorsList, "tags"),
    //     [component.tagIds]
    // );

    // const saveNewTags = () => Promise.all(
    //     newTags.map(newTag =>
    //         // Todo: don't mutate state directly!
    //         addTag(newTag).then(tag => component.tagIds.push(tag.id))
    //     )
    // );

    const resetErrors = () =>
    {
        const errors = emptyErrorState();
        component.input.forEach((input) =>
            errors["input"][input.machineName] = []
        );
        component.output.forEach((output) =>
            errors["output"][output.machineName] = []
        );
        setErrorsList(errors);
    };

    const handleSubmit = (e) =>
    {
        e.preventDefault();

        handleUpdate(() => leavePage(DASHBOARD));
    };

    const handlePublish = () => handleUpdate(() => {
        setShowPublishModal(true);
    });

    const handleUpdate = (updateResponseHandler) =>
    {
        if (isFormValid(tab)) {
            const dto = {
                displayName: component.displayName,
                description: component.description,
                input: component.input,
                output: component.output,
                status: getStatus(),
                publishmentStatus:
                    component.publishmentStatus || "NOT_PUBLISHED",
                configurationSections: component.configurationSections,
                configurations: component.configurations,
            };

            id && updateComponent(id, dto)
                .then(() => isPublished() && updateComponentFee())
                .then(() => updatedFees && updateResponseHandler())
                .catch((error) => {
                    catchUnauthorized(error, setUser);
                    if (error instanceof BadRequest) {
                        toast.error(GENERIC_INPUT_ERROR, TOAST_SETTINGS);
                        handleBEerrors(error, dto);
                    } else {
                        return error.message === "REJECTED_PAYMENT" ?
                            toast.error(REJECTED_PAYMENT, TOAST_SETTINGS) :
                            toast.error(GENERIC_ERROR, TOAST_SETTINGS);
                    }
                });
        }
    };

    const getStatus = () =>
    {
        if (isPublished()) {
            return component.status === "DISABLED" ? "DISABLED" : "ENABLED";
        } else {
            return "TO_BE_PUBLISHED";
        }
    };

    const isPublished = () => component.publishmentStatus === "PUBLISHED";
    const canBePublished = () =>
        publishedComponent && changedFees.runPrice && changedFees.downloadPrice;

    const handleBEerrors = (error, dto) => {
        const errors = { ...errorsList };
        if (error instanceof BadRequest) {
            error.error.then((error) => error.violations &&
                Object.keys(error.violations).forEach((element) => {
                    const field = element.split(".").pop();
                    if (field === "title") {
                        const [...iOfields] = element.split(".");
                        iOfields.forEach((iOfield) => {
                            if (stringHasBrackets(iOfield)) {
                                const fieldName = iOfield.substring(
                                    0, iOfield.indexOf("[")
                                );
                                const index = iOfield.split("[")
                                    .pop()
                                    .split("]")[0];
                                const machineName =
                                    dto[fieldName][index].machineName;
                                errors[fieldName][machineName] =
                                    [UNKNOWN_ERROR];
                            }
                        });
                    } else {
                        errors[field].indexOf(UNKNOWN_ERROR) === -1 &&
                            errors[field].push(UNKNOWN_ERROR);
                    }
                    setErrorsList(errors);
                })
            );
        }
    };

    const stringHasBrackets = (value) =>
        value.includes("[") && value.includes("]");

    const handleCancel = () => isStateChanged ?
        setShowConfirmModal(true) : leavePage(DASHBOARD);

    const handleChangeTab = (event, value) => isFormValid(tab) && setTab(value);

    const handleClickNext = () =>
        isFormValid(tab) && setTab((Number(tab) + 1).toString());

    const handleClickPrevious = () => setTab((Number(tab) - 1).toString());

    const isFormValid = (tabValue) =>
    {
        const errors = { ...errorsList };
        let isValid = true;

        const addMandatoryError = (field) =>
        {
            errors[field].indexOf(MANDATORY_ERROR) === -1 &&
                errors[field].push(MANDATORY_ERROR);
            isValid = false;
        };

        const checkMandatoryTitleForIO = (ioObject, type) =>
        {
            if (!ioObject.title) {
                errors[type][ioObject.machineName] = [MANDATORY_ERROR];
                isValid = false;
            }
        };

        switch(tabValue) {
            case TAB_GENERAL_DETAILS.value:
                if (!component.displayName) {
                    addMandatoryError("displayName", errors);
                }
                if (!component.description) {
                    addMandatoryError("description", errors);
                }
                // if (!component.tagIds?.length && !newTags.length) {
                //     addMandatoryError("tags", errors);
                // }
                break;
            case TAB_INPUT_OUTPUT.value:
                component.input.forEach((input) =>
                    checkMandatoryTitleForIO(input, "input")
                );
                component.output.forEach((output) =>
                    checkMandatoryTitleForIO(output, "output")
                );
                break;
            case TAB_CONFIGURATIONS.value:
                isValid = true;
                break;
            case TAB_FEES.value:
                isValid = true;
                break;
            default:
                isValid = true;
        }

        setErrorsList(errors);

        return isValid;
    };

    const leavePage = (destination) =>
    {
        resetErrors();
        navigate(destination);
    };

    // const updateTagIds = (tagId, action) =>
    // {
    //     const tagIds = [... component.tagIds];
    //     action === "add" ? tagIds.push(tagId) :
    //         tagIds.splice(tagIds.indexOf(tagId), 1);
    //
    //     setComponent({ ...component, tagIds: tagIds });
    //     setStateChanged(true);
    // };

    // const clearTags = () =>
    // {
    //     setComponent({ ...component, tagIds: [] });
    //     setStateChanged(true);
    //     setNewTags([]);
    // };

    const onConfigurationSectionChange = (machineName, description) =>
    {
        setComponent({
            ...component,
            configurationSections: updateDescription(
                machineName, description, component.configurationSections)
        });
        setStateChanged(true);
    };

    const updateDescription = (machineName, description, configSections) =>
        configSections.map((section) => {
            if (section.machineName === machineName) {
                return { ...section, description };
            } else if (section.configurationSections) {
                return {
                    ...section,
                    configurationSections: updateDescription(
                        machineName, description, section.configurationSections
                    )
                };
            }

            return section;
        });

    const onConfigurationChange = (e, configuration) =>
    {
        const configurations = component.configurations.map(item =>
            item.machineName === configuration.machineName ?
                { ...item, description: e.target.value } : item
        );
        setComponent({ ...component, configurations: configurations });
        setStateChanged(true);
    };

    const onInputOutputChange = (event, ioObject, field) =>
    {
        const fieldToUpdate = component[field].map(item =>
            item.machineName === ioObject.machineName ?
                { ...item, title: event.target.value } : item
        );
        setComponent({ ...component, [field]: fieldToUpdate });
        setStateChanged(true);

        removeErrorWhenTyping(
            errorsList,
            setErrorsList,
            field,
            ioObject.machineName
        );
    };

    const onDisplayNameChange = (e) =>
    {
        setComponent({ ...component, displayName: e.target.value });
        setStateChanged(true);
    };

    const onDescriptionChange = (e) =>
    {
        setComponent({ ...component, description: e.target.value });
        setStateChanged(true);
    };

    const onFeeChange = (type, price) =>
    {
        setChangedFees({ ...changedFees, [type]: price });
        setStateChanged(true);
        setUpdatedFees(true);
    };

    const onCloseModal = () => {
        setPublishedComponent(null);
        setShowPublishModal(false);
    };

    return (
        <div className="component-form">
            <div className="form-heading"><h2>Edit component</h2></div>
            <TabContext value={ tab }>
                <TabList  onChange={ handleChangeTab }>
                    <Tab
                        label={ TAB_GENERAL_DETAILS.label }
                        value={ TAB_GENERAL_DETAILS.value }
                    />
                    <Tab
                        label={ TAB_INPUT_OUTPUT.label }
                        value={ TAB_INPUT_OUTPUT.value }
                    />
                    <Tab
                        label={ TAB_CONFIGURATIONS.label }
                        value={ TAB_CONFIGURATIONS.value }
                    />
                    <Tab label={ TAB_FEES.label } value={ TAB_FEES.value }/>
                </TabList>

                <form onSubmit={ handleSubmit }>
                    <TabPanel value={ TAB_GENERAL_DETAILS.value }>
                        <p>{ TAB_GENERAL_DETAILS.title }</p>
                        <GeneralDetailsTab
                            component={ component }
                            onDisplayNameChange={ onDisplayNameChange }
                            onDescriptionChange={ onDescriptionChange }
                            errorsList={ errorsList }
                        />
                        <ActionButtons
                            tab={ TAB_GENERAL_DETAILS }
                            handleCancel={ handleCancel }
                            handleClickNext={ handleClickNext }
                            showConfirmModal={ showConfirmModal }
                            handleConfirm={ () => leavePage(DASHBOARD) }
                        />
                    </TabPanel>
                    <TabPanel value={ TAB_INPUT_OUTPUT.value }>
                        <p>{ TAB_INPUT_OUTPUT.title }</p>
                        <InputOutputTab
                            component={ component }
                            onInputOutputChange={ onInputOutputChange }
                            errorsList={ errorsList }
                        />
                        <ActionButtons
                            tab={ TAB_INPUT_OUTPUT }
                            handleCancel={ handleCancel }
                            handleClickPrevious={ handleClickPrevious }
                            handleClickNext={ handleClickNext }
                            showConfirmModal={ showConfirmModal }
                            handleConfirm={ () => leavePage(DASHBOARD) }
                        />
                    </TabPanel>
                    <TabPanel value={ TAB_CONFIGURATIONS.value }>
                        <p>{ TAB_CONFIGURATIONS.title}</p>
                        <ConfigurationsTab
                            component={ component }
                            onConfigurationSectionChange={
                                onConfigurationSectionChange
                            }
                            onConfigurationChange={ onConfigurationChange }
                        />
                        <ActionButtons
                            tab={ TAB_CONFIGURATIONS }
                            handleCancel={ handleCancel }
                            handleClickNext={ handleClickNext }
                            handleClickPrevious={ handleClickPrevious }
                            showConfirmModal={ showConfirmModal }
                            handleConfirm={ () => leavePage(DASHBOARD) }
                        />
                    </TabPanel>
                    <TabPanel value={ TAB_FEES.value }>
                        <p>{ TAB_FEES.title}</p>
                        <FeesTab
                            fees={ changedFees }
                            onFeeChange={ onFeeChange }
                        />
                        <ActionButtons
                            tab={ TAB_FEES }
                            handleCancel={ handleCancel }
                            handleClickPrevious={ handleClickPrevious }
                            isPublished={ isPublished() }
                            canBePublished={ canBePublished() }
                            handlePublish={ handlePublish }
                            isStateChanged={ isStateChanged }
                            showConfirmModal={ showConfirmModal }
                            handleConfirm={ () => leavePage(DASHBOARD) }
                        />
                    </TabPanel>
                    <ConfirmModal
                        show={ showConfirmModal }
                        setShow={ setShowConfirmModal }
                        message={ LEAVE_CONFIRM_MESSAGE }
                        handleConfirm={ () => leavePage(DASHBOARD) }
                    />
                </form>
            </TabContext>
            {
                publishedComponent &&
                <PublishModal
                    show={ showPublishModal }
                    setShow={ setShowPublishModal }
                    component={ publishedComponent }
                    onCloseModal={ onCloseModal }
                    showSetFees={ false }
                />
            }
        </div>
    );
};

export default EditComponent;
