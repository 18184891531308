import { ethers } from "ethers";
import ibl from "../../ethereum/ibl";
import iblERC20 from "../../ethereum/iblERC20";
import { configurations } from "../configurations";

class FrontEndLibrary
{
    constructor()
    {
        if (window.ethereum) {
            this.provider = new ethers.providers.Web3Provider(window.ethereum);
            this.iblContract = ibl(
                this.provider.getSigner(),
                configurations.iblAddress
            );
            this.iblERC20 = iblERC20(
                this.provider.getSigner(),
                configurations.iblErc20Address
            );
        }
    }

    getBalance = (userAddress) => this.iblERC20.balanceOf(userAddress)
        .catch(error => {
            throw new Error("Error getting balance:", error);
        });

    getComponent = (componentId) => this.iblContract.componentData(componentId)
        .catch(error => {
            throw new Error("Error getting components:", error);
        });

    setNewPrice = (component, downloadFeeDiff, onTransactionInitialize) => {
        const args = [
            component.id, component.runPrice, component.downloadPrice
        ];

        downloadFeeDiff > 0 && args.push({ value: downloadFeeDiff });

        return this.iblContract.setNewPrice(...args)
            .then((tx) => {
                onTransactionInitialize && onTransactionInitialize();

                return tx;
            })
            .then((tx) => tx.wait())
            .catch(() => {
                throw new Error("REJECTED_PAYMENT");
            });
    };

    addComponentToContract = (from, component, onPaymentProcessing) =>
        this.iblContract.connect(this.provider.getSigner(from))
            .addComponent(component, { value: component.downloadPrice })
            .then((tx) => {
                onPaymentProcessing();

                return tx.wait()
                    .then(() => ethers.utils.serializeTransaction(tx));
            })
            .catch((error) => {
                throw new Error(
                    error.message.includes("rejected transaction") ?
                        "REJECTED_PAYMENT" : ("Error adding components:", error)
                );
            });

    handleDownloadApp = (onBoardComponentIds) =>
        this.getDownloadFee(onBoardComponentIds).then((fee) =>
            this.iblContract
                .downlodApplication(onBoardComponentIds, { value: fee })
                .then((tx) => tx.wait())
                .catch((error) => {
                    throw new Error("Error download app:", error);
                })
        );

    getDownloadFee = (onBoardComponentIds) =>
        this.iblContract.calculatedownlodFeeApplication(onBoardComponentIds)
            .catch((error) => {
                throw new Error("Error getting downloadFee:", error);
            });

    handleRunApp = (onBoardComponentIds, value) =>
        this.iblContract.runApplication(onBoardComponentIds, { value: value })
            .then((tx) => tx.wait())
            .catch(error => {
                throw new Error("Error downloading application:", error);
            });

    getAppFee = (appId) => this.iblContract.applicationFee(appId)
        .catch((error) => {
            throw new Error("Error getting application:", error);
        });

    setAppFee = (appId, fee, onPaymentProcessing) =>
        this.iblContract.setNewApplicationFee(appId, fee)
            .then((tx) => {
                onPaymentProcessing();

                return tx.wait();
            })
            .catch((error) => {
                throw new Error("Error setting price for application:", error);
            });

    addAppToContract = (appId, fee, onPaymentProcessing) =>
        this.iblContract.addApplicationFee(appId, fee)
            .then((tx) => {
                onPaymentProcessing();

                return tx.wait();
            })
            .catch((error) => {
                throw new Error(error.error.message);
            });

    getAppOwner = (appId) => this.iblContract.applicationOwner(appId);

    getApplicationFee = (applicationId) =>
        this.iblContract.applicationTotalFeeAccrued(applicationId)
            .catch(this.catchError);

    getAccumulatedApplicationsFee = (userAddress) =>
        this.iblContract.applicationFeeReward(userAddress)
            .catch(this.catchError);

    getAccumulatedComponentsFee = (userAddress) =>
        this.iblContract.ownerNativeFeeAcc(userAddress)
            .catch(this.catchError);

    getAccumulatedProtocolFee = (userAddress) =>
        this.iblContract.accAccruedFees(userAddress)
            .catch(this.catchError);

    getAccumulatedRewards = (userAddress) =>
        this.iblContract.accRewards(userAddress)
            .catch(this.catchError);

    getStakedAmount = (userAddress) =>
        this.iblContract.accWithdrawableStake(userAddress)
            .catch(this.catchError);

    claimComponentFees = (from) =>
        this.iblContract.connect(this.provider.getSigner(from))
            .claimComponentOwnerFees()
            .then((tx) => tx.wait())
            .catch(this.catchError);

    claimAppFees = (from) =>
        this.iblContract.connect(this.provider.getSigner(from))
            .claimApplicationFee()
            .then((tx) => tx.wait())
            .catch(this.catchError);

    claimProtocol = (from) =>
        this.iblContract.connect(this.provider.getSigner(from))
            .claimFees()
            .then((tx) => tx.wait())
            .catch(this.catchError);

    claimAllRewards = (from) =>
        this.iblContract.connect(this.provider.getSigner(from))
            .claimRewards()
            .then((tx) => tx.wait())
            .catch(this.catchError);

    approveTokens = (amount) =>
        this.iblERC20.approve(this.iblContract.address, amount)
            .then((tx) => tx.wait())
            .catch(this.catchError);

    getAllowance = (from) =>
        this.iblERC20.allowance(from, this.iblContract.address);

    stakeRewards = (from, amount) => this.getAllowance(from)
        .then((allowance) => {
            if (allowance.lt(amount)) {
                throw new Error("Insufficient allowance");
            } else {
                return this.iblContract.connect(this.provider.getSigner(from))
                    .stake(amount)
                    .then((tx) => tx.wait())
                    .catch(this.catchError);
            }
        });

    unstakeRewards = (from, amount) =>
        this.iblContract.connect(this.provider.getSigner(from))
            .unstake(amount)
            .then((tx) => tx.wait())
            .catch(this.catchError);

    catchError = (error) => {
        throw new Error(error.error?.message);
    };
}

export default new FrontEndLibrary();
