import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

const ControlledCheckbox = (props) => 
{
    const [checked, setChecked] = useState(props.defaultValue || false);
    const [disabled, setDisabled] = useState(false);

    useEffect(
        () => {
            if (props.configuration.required) {
                setChecked(true);
                setDisabled(true);
            }
        }, 
        []
    );

    useEffect(
        () => {
            props.handleConfigurationChange(checked, props.configuration);
        }, 
        [checked]
    );

    return (
        <Checkbox
            checked={ checked }
            onChange={ (e) => setChecked(e.target.checked) }
            inputProps={{ "aria-label": "controlled" }}
            label={ props.configuration.title }
            disabled={ disabled }
        />
    );
};

export default ControlledCheckbox;