import { checkApiResponse, get, post } from "../../_common/services/webClient";
import { configurations } from "../../_common/configurations";

const SUBSCRIPTIONS_API = configurations.apiUrl + "/subscriptions";

export const getActiveSubscriptions = (appId) =>
    get(SUBSCRIPTIONS_API + "/application/" + appId).then(checkApiResponse)
        .then(response => response.json());

export const createSubscription = (subscription) =>
    post(SUBSCRIPTIONS_API, subscription).then(checkApiResponse)
        .then(response => response.json());
