import React from "react";
import Icon from "../../_common/components/Icon";

const IdeControls = (props) =>
{
    const grid = props.grid;
    const setGrid = props.setGrid;
    const onBoardComponents = props.onBoardComponents;

    const zoomOut = () =>
    {
        grid.rows.unshift(grid.rows[0] - 1);
        grid.rows.push(grid.rows[grid.rows.length - 1] + 1);
        grid.cols.unshift(grid.cols[0] - 1);
        grid.cols.push(grid.cols[grid.cols.length - 1] + 1);
        setGrid({ ...grid, rows: grid.rows, cols: grid.cols });
    };
    const zoomIn = () =>
    {
        if (grid.rows.length > 3) {
            grid.rows.shift();
            grid.rows.pop();
        }
        if (grid.cols.length > 3) {
            grid.cols.shift();
            grid.cols.pop();
        }

        setGrid({ ...grid, rows: grid.rows, cols: grid.cols });
    };
    const moveLeft = () =>
    {
        grid.cols.shift();
        grid.cols.push(grid.cols[grid.cols.length - 1] + 1);
        setGrid({ ...grid, cols: grid.cols });
    };
    const moveRight = () =>
    {
        grid.cols.unshift(grid.cols[0] - 1);
        grid.cols.pop();
        setGrid({ ...grid, cols: grid.cols });
    };
    const moveUp = () =>
    {
        grid.rows.shift();
        grid.rows.push(grid.rows[grid.rows.length - 1] + 1);
        setGrid({ ...grid, rows: grid.rows });
    };
    const moveDown = () =>
    {
        grid.rows.unshift(grid.rows[0] - 1);
        grid.rows.pop();
        setGrid({ ...grid, rows: grid.rows });
    };

    const zoomToFit = () => onBoardComponents &&
        setGridDimensions(onBoardComponents, grid, setGrid);

    return (
        <div className="control-buttons">
            <button className="btn button" type="button" onClick={ zoomIn }
                data-toggle="tooltip" data-placement="top" title="zoom in">
                <Icon name="plus"/>
            </button>
            <button className="btn button" type="button" onClick={ zoomOut }
                data-toggle="tooltip" data-placement="top" title="zoom out">
                <Icon name="minus"/>
            </button>
            <button className="btn button" type="button" onClick={ moveLeft }
                data-toggle="tooltip" data-placement="top" title="move left">
                <Icon name="arrowLeft"/>
            </button>
            <button className="btn button" type="button" onClick={ moveRight }
                data-toggle="tooltip" data-placement="top" title="move right">
                <Icon name="arrowRight"/>
            </button>
            <button className="btn button" type="button" onClick={ moveUp }
                data-toggle="tooltip" data-placement="top" title="move up">
                <Icon name="arrowUp"/>
            </button>
            <button className="btn button" type="button" onClick={ moveDown }
                data-toggle="tooltip" data-placement="top" title="move down">
                <Icon name="arrowDown"/>
            </button>
            <button className="btn button" type="button" onClick={ zoomToFit }
                data-toggle="tooltip" data-placement="top" title="zoom to fit">
                <Icon name="arrowsMaximize"/>
            </button>
        </div>
    );
};

export const setGridDimensions = (onBoardComponents, grid, setGrid) =>
{
    const rows = [];
    const cols = [];

    Object.values(onBoardComponents).map(component => component.dropZoneId)
        .map(dropZoneId => dropZoneId.split("_"))
        .map(([row, column]) => {
            rows.push(row);
            cols.push(column);
        });

    setGrid({ ...grid, rows: minMaxArray(rows), cols: minMaxArray(cols) });
};

export const minMaxArray = (array) =>
{
    const min = Math.min(...array);
    const max = Math.max(...array);
    const diff = max - min + 1;

    return Array.from({ length: diff < 3 ? 3 : diff }, (_, i) => i + min);
};

export default IdeControls;
