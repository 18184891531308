import React, { useContext, useState, useRef, useEffect } from "react";
import LogoutButton from "../../../user/components/LogoutButton";
import "./nav.sass";
import { CURRENT_USER } from "../../context";
import { ACCOUNT_ROUTE, DASHBOARD, HOME_ROUTE } from "../../constants/routes";
import Logo from "../../assets/images/Logo";
import Arrow from "../../assets/images/Arrow";
import Account from "../../assets/images/Account";
import { useLocation } from "react-router-dom";

const Nav = () =>
{
    const { user } = useContext(CURRENT_USER);
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const name = user.name || walletAddress(user);

    const handleClickOutside = (event) =>
        ref.current && !ref.current.contains(event.target) && setOpen(false);
    const onClick = () => setOpen(true);

    useEffect(
        () => {
            document.addEventListener("click", handleClickOutside, true);

            return () => document
                .removeEventListener("click", handleClickOutside, true);
        },
        [open]
    );

    const checkLocation = (location) =>
        location === useLocation().pathname ? " active" : "";

    return (
        <nav className="navbar">
            <div className="container-fluid">
                <div className="menu-items">
                    <a
                        href={ HOME_ROUTE }
                        className={ "nav-link ibl" + checkLocation(HOME_ROUTE) }
                    >
                        <Logo />
                    </a>
                </div>
                <div className="menu-items">
                    <a
                        href={ HOME_ROUTE }
                        className={ "nav-link" + checkLocation(HOME_ROUTE) }
                    >
                        Fees & Rewards
                    </a>
                    <a
                        href={ DASHBOARD }
                        className={ "nav-link" + checkLocation(DASHBOARD) }
                    >
                        Dashboard
                    </a>
                    <div
                        className={
                            "nav-link dropdown-container" +
                            checkLocation(ACCOUNT_ROUTE)
                        }
                    >
                        <div
                            className="dropdown-opener"
                            onClick={ onClick }
                        >
                            { name } <Arrow />
                        </div>
                        {
                            open &&
                            <div ref={ ref } className="dropdown-list" >
                                <a
                                    href={ ACCOUNT_ROUTE }
                                    className="nav-link account"
                                >
                                    Account settings <span><Account/></span>
                                </a>
                                <LogoutButton />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </nav>
    );
};

const walletAddress = (user) =>
    user.username.substring(0, 6) + "..." + user.username.substring(38);

export default Nav;
