export class BadRequest extends Error
{
    type = "BadRequestException";
    error;

    constructor(error)
    {
        super();
        this.error = error;
    }
}
