import React from "react";

const MagicWand = () =>
{
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="60" height="60" viewBox="0 0 60 60">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_40" data-name="Rectangle 40" width="60"
                        height="60" transform="translate(219 319)" />
                </clipPath>
            </defs>
            <g id="Mask_Group_1" data-name="Mask Group 1"
                transform="translate(-219 -319)" clipPath="url(#clip-path)">
                <g id="magic-wand" transform="translate(216.675 316.675)">
                    <g id="Group_27" data-name="Group 27">
                        <path id="Path_6" data-name="Path 6"
                            d="M51.117,45.149,48.331,44.4l2.42-1.57L50.6,
                            39.953l2.241,1.816,2.693-1.035L54.5,43.427l1.816,
                            2.241-2.881-.152-1.57,2.42Z" />
                    </g>
                    <g id="Group_28" data-name="Group 28">
                        <path id="Path_7" data-name="Path 7" 
                            d="M30.874,7.521l-2.787-.746,
                            2.42-1.57-.152-2.881L32.6,4.141l2.693-1.035L34.255,
                            5.8,36.071,8.04,33.19,7.888l-1.57,2.42Z" />
                    </g>
                    <g id="Group_29" data-name="Group 29">
                        <path id="Path_8" data-name="Path 8" d="M17.956,15.748,
                            15.169,15l2.42-1.57-.152-2.881,2.241,1.816,
                            2.693-1.035-1.035,2.693,1.816,2.241-2.881-.152-1.57,
                            2.42Z" />
                    </g>
                    <g id="Group_30" data-name="Group 30">
                        <path id="Path_9" data-name="Path 9" 
                            d="M56.952,33.6l-2.787-.746,2.42-1.57L56.433,
                            28.4l2.241,1.816,2.693-1.035-1.035,2.693,1.816,
                            2.241-2.881-.152-1.57,2.42Z" />
                    </g>
                    <g id="Group_31" data-name="Group 31">
                        <path id="Path_10" data-name="Path 10" 
                            d="M51.117,13.748,48.331,13l2.42-1.57L50.6,
                            8.551l2.241,1.816,2.693-1.035L54.5,12.026l1.816,
                            2.241-2.881-.152-1.57,2.42Z" />
                    </g>
                    <g id="Group_32" data-name="Group 32">
                        <path id="Path_11" data-name="Path 11" 
                            d="M44.388,25.4l3.1-8.067-8.067,3.1L32.708,15l.455,
                            8.629-7.248,4.7,6.068,1.623L3.1,58.833a2.046,
                            2.046,0,0,0,2.893,2.893l28.88-28.88L36.5,
                            38.908l4.7-7.248,8.629.455Z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default MagicWand;