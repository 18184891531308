import React, { useContext } from "react";
import ComponentList from "./ComponentList";
import ApplicationList from "./ApplicationList";
import { LOGIN_ROUTE } from "../../_common/constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { CURRENT_USER } from "../../_common/context";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import "./dashboard.sass";

const DashboardPage = () =>
{
    const navigate = useNavigate();
    const { tab } = useParams();
    const { user } = useContext(CURRENT_USER);
    !user.username && navigate(LOGIN_ROUTE);

    const handleChange = (event, tab) => navigate("/dashboard/" +tab);

    return (
        <div className="dashboard-container">
            <TabContext value={ tab ?? "components" }>
                <TabList onChange={ handleChange }>
                    <Tab label="My Components" value="components" />
                    <Tab label="My Apps" value="applications" />
                </TabList>
                <TabPanel value="components"><ComponentList/></TabPanel>
                <TabPanel className="applications" value="applications">
                    <ApplicationList/>
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default DashboardPage;
