import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import SelectField from "./SelectField";
import ControlledCheckbox from "./ControlledCheckbox";
import RadioButtonSelect from "./RadioButtonSelect";
import "../style/sidebar.sass";
import { MANDATORY_ERROR } from "../../_common/constants/errors";
import { isNotEmpty } from "../../_common/helper/checks";
import { removeErrorWhenTyping } from "../../_common/helper/functions";
import Icon from "../../_common/components/Icon";

const CustomizeComponentSidebar = (props) =>
{
    const ELEMENT_MAPPINGS = {
        "INPUT": "input",
        "TEXT_FIELD": "input",
        "SELECT": SelectField,
        "CHECKBOX": ControlledCheckbox,
        "RADIOS": RadioButtonSelect
    };

    const configuredComponent = props.configuredComponent;
    const component = configuredComponent.component;
    const [errorsList, setErrorsList] = useState({ configurations: {}});
    const [configurations, setConfigurations] = useState(() => {
        const configurations = configuredComponent.configData.configurations;
        const errors = { ...errorsList };
        component.configurations
            .filter(
                configuration => !(configuration.type === "CHECKBOX") &&
                    configuration.required === true
            )
            .forEach(configuration => {
                errors.configurations[configuration.machineName] = [];
            });
        setErrorsList(errors);

        return component.configurations.reduce(
            (result, current) => {
                const machineName = current.machineName;
                result[machineName] = configurations?.[machineName] ?? null;

                return result;
            },
            {}
        );
    });

    useEffect(
        () => {
            configuredComponent.configData.configurations && isFormValid();
        },
        []
    );

    const onSave = () =>
    {
        if (isFormValid()) {
            const onBoardComponent = { ...configuredComponent };
            onBoardComponent["hasConfigurationsCompleted"] = true;
            onBoardComponent.configData["configurations"] = configurations;
            onBoardComponent.isUpdated = false;

            props.updateAndResetSidebar(
                { componentsToUpdate: [onBoardComponent] }
            );
        }
    };

    const isConfigurationValid = (configuration) =>
        Array.isArray(configurations[configuration]) ?
            configurations[configuration].length > 0 :
            isNotEmpty(configurations[configuration]);

    const isFormValid = () =>
    {
        const errors = { ...errorsList };
        let isValid = true;
        Object.keys(errorsList.configurations)
            .filter((config) => !isConfigurationValid(config))
            .forEach(config => {
                const configErrors = errors.configurations;
                configErrors[config].indexOf(MANDATORY_ERROR) === -1 &&
                    configErrors[config].push(MANDATORY_ERROR);
                isValid = false;
            });
        setErrorsList(errors);

        return isValid;
    };

    const handleConfigurationChange = (e, configuration) =>
    {
        const newConfigurations = configurations;
        newConfigurations[configuration.machineName] = e;
        setConfigurations(newConfigurations);
        configuration.required &&
            removeErrorWhenTyping(
                errorsList,
                setErrorsList,
                "configurations",
                configuration.machineName
            );
    };

    const displayConfigurationTooltip = (configuration) => (
        <Icon
            name="info_tooltip"
            className="tooltip-icon"
            size="lg"
            data-toggle="tooltip"
            data-placement="right"
            title={ configuration.description }
        />
    );

    const displayConfiguration = (configSection, type) =>
    {
        const renderSectionTitle = () => type === "mainSection" ?
            <h5 className="section-title">{ configSection.title }</h5> :
            <p className="section-subtitle">{ configSection.title }</p>;
        const configIsInSection = (configuration) =>
            configuration.sectionMachineName === configSection.machineName;

        return (
            <div
                className={ configSection.machineName }
                key={ configSection.machineName }
            >
                { renderSectionTitle() }
                {
                    component.configurations.filter(configIsInSection)
                        .map(configuration => generateConfigurationDiv(
                            configuration, configuration.required ? "*" : ""
                        ))
                }
                { renderConfigSection(configSection, "subsection") }
            </div>
        );
    };

    const renderConfigSection = (configSection, type) =>
        configSection.configurationSections &&
        configSection.configurationSections
            .map(section => displayConfiguration(section, type));

    const generateConfigurationDiv = (configuration, isRequired) => (
        <div key={ configuration.title }>
            <div className="form-group">
                <label className="form-label">
                    { configuration.title + isRequired }
                    {
                        configuration.description &&
                        displayConfigurationTooltip(configuration)
                    }
                </label>

                <div className={ getClassName(configuration) }>
                    { generateDomElement(configuration) }
                </div>
            </div>
            {
                configuration.machineName in errorsList.configurations &&
                errorsList.configurations[configuration.machineName]
                    .map(displayError)
            }
        </div>
    );

    const generateDomElement = (configuration) =>
    {
        const defaultValue = configurations[configuration.machineName];
        const element = ELEMENT_MAPPINGS[configuration.type];
        const properties = ["input", "textarea"].includes(element) ?
            {
                defaultValue: defaultValue,
                onChange: (e) =>
                    handleConfigurationChange(e.target.value, configuration),
                className: "form-control"
            } :
            {
                className: "form-control",
                configuration: configuration,
                defaultValue: defaultValue,
                handleConfigurationChange: handleConfigurationChange
            };

        return React.createElement(element, properties);
    };

    const getClassName = (configuration) =>
        errorsList.configurations[configuration.machineName]?.length > 0 ?
            "not-valid" : "";

    const displayError = (error, index) =>
        <div key={ index } className="error-message">{ error }</div>;

    return (
        <Drawer
            variant={ "persistent" }
            anchor={ "right" }
            open={ props.sidebarOpened }
            className="component-sidebar"
        >
            <div className="customize-sidebar">
                <h4 className="section-title">{ component.name }</h4>
                <p className="section-subtitle">Customize component</p>
                <>{ renderConfigSection(component, "mainSection") }</>
                <div className="actions">
                    <button
                        type="button"
                        className="btn cancel-btn"
                        onClick={ props.updateAndResetSidebar }
                    >
                        Cancel
                    </button>
                    <button
                        type="button" className="btn button" onClick={ onSave }
                    >
                        Done
                    </button>
                </div>
            </div>
        </Drawer>
    );
};

export default CustomizeComponentSidebar;
