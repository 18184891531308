import React from "react";

const Account = () =>
{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.131"
            viewBox="0 0 20 19.131">
            <path id="Subtraction_2" data-name="Subtraction 2"
                d="M-1970,19.131h-20V0h20V19.13Zm-10-7.772a12.92,12.92,0,0,
                0-4.413.9c-1.207.491-2.646,1.357-2.646,
                2.688v1.793h14.118V14.946c0-1.331-1.439-2.2-2.646-2.688A12.918,
                12.918,0,0,0-1980,11.359Zm0-8.968a3.563,3.563,0,0,0-3.53,
                3.587A3.563,3.563,0,0,0-1980,9.565a3.562,3.562,0,0,0,
                3.529-3.587A3.562,3.562,0,0,0-1980,2.391Z"
                transform="translate(1990)"/>
        </svg>
    );
};

export default Account;