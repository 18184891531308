import {
    faPlug,
    faCircleChevronDown,
    faPencil,
    faCircleChevronUp,
    faBan,
    faTrash,
    faTriangleExclamation,
    faCircleExclamation,
    faCircleCheck,
    faEllipsisV,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faMaximize,
    faPlus,
    faMinus,
    faDownload,
    faUserLargeSlash,
    faClose,
    faRotate,
    faInfoCircle,
    faClock,
    faCoins,
    faStar,
    faChartPie
} from "@fortawesome/free-solid-svg-icons";

export default {
    connect: faPlug,
    circleArrowDown: faCircleChevronDown,
    edit: faPencil,
    circleArrowUp: faCircleChevronUp,
    disabled: faBan,
    trash: faTrash,
    pending: faClock,
    used: faTriangleExclamation,
    error: faCircleExclamation,
    imported: faCircleCheck,
    threeDots: faEllipsisV,
    arrowLeft: faArrowLeft,
    arrowRight: faArrowRight,
    arrowUp: faArrowUp,
    arrowDown: faArrowDown,
    arrowsMaximize: faMaximize,
    plus: faPlus,
    minus: faMinus,
    download: faDownload,
    not_owned_by_user: faUserLargeSlash,
    missing_used_component_theme: faTriangleExclamation,
    close: faClose,
    update_error: faRotate,
    info_tooltip: faInfoCircle,
    coins: faCoins,
    star: faStar,
    protocol: faChartPie
};
