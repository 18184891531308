import React from "react";

const Logo = () =>
{
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="76.567" height="45" viewBox="0 0 76.567 45">
            <g id="Group_17" data-name="Group 17"
                transform="translate(-156.239 -124.001)">
                <rect id="Rectangle_12" data-name="Rectangle 12"
                    width="11.437" height="10.435"
                    transform="translate(156.239 124.001)"/>
                <path id="Path_1" data-name="Path 1"
                    d="M156.24,161.525l11.437-6.531v31.14H156.24Z"
                    transform="translate(0 -17.133)"/>
                <path id="Path_2" data-name="Path 2"
                    // eslint-disable-next-line
                    d="M223.015,137.935h14.8V169H211.579l-7.958-7.192V124h11.436V143.98Zm-7.958,20.691h11.318V148.309H215.057Z"
                    transform="translate(-26.193 0)"/>
                <path id="Path_3" data-name="Path 3"
                    d="M313.319,124v34.625L313.26,169H301.883V124Z"
                    transform="translate(-80.513 0)"/>
            </g>
        </svg>
    );
};

export default Logo;