import React, { useState } from "react";
import { ethers } from "ethers";
import Icon from "../../_common/components/Icon";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../_common/constants/messageStyles";
import { CLAIM_SUCCESSFUL } from "../../_common/constants/notifications";
import { CLAIM_ERROR } from "../../_common/constants/errors";

const Claim = (props) =>
{
    const [showSpinner, setShowSpinner] = useState(false);

    const handleOnClick = () => {
        setShowSpinner(true);
        props.claim()
            .then(() => {
                props.resetState();
                toast.success(CLAIM_SUCCESSFUL, TOAST_SETTINGS);
            })
            .catch((error) => toast.error(
                <div>
                    <div>{ CLAIM_ERROR }</div>
                    <div>{ error.message }</div>
                </div>,
                TOAST_SETTINGS
            ))
            .finally(() => setShowSpinner(false));
    };

    return (
        <div className="claim">
            <div className="claim__text">
                <div>
                    <div className="section-title">{ props.title }</div>
                    <div>
                        { ethers.utils.formatEther(props.value) }
                        { " " + props.currency }
                    </div>
                </div>
                <div>
                    <button
                        className="btn button col"
                        disabled={ props.value.isZero() || showSpinner }
                        onClick={ handleOnClick }
                    >
                        {
                            !showSpinner ? props.buttonText :
                                <div className="button-spinner spinner-border"/>
                        }
                    </button>
                </div>
            </div>
            <div className="claim__icon">
                <Icon name={ props.iconName }/>
            </div>
        </div>
    );
};

export default Claim;