import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import "../style/sidebar.sass";
import { ioTypesCanBeConverted } from "../common/helperFunctions";
import { MANDATORY_ERROR } from "../../_common/constants/errors";

const CustomizeConnectionSidebar = (props) =>
{
    const {
        connection,
        updateAndResetSidebar,
        onBoardComponents
    } = props;
    const [buttonName, setButtonName] = useState("");
    const [inputMappings, setInputMappings] = useState({});
    const [availableOutputs, setAvailableOutputs] = useState({});
    const [errorsList, setErrorsList] = useState({ inputMappings: {}});

    useEffect(
        () => { onBoardComponents && connection && initializeState(); },
        [connection]
    );

    useEffect(
        () => {
            resetErrors();
            onBoardComponents[connection?.end?.gridItem]
                .configData.inputMappings && isFormValid();
        },
        [inputMappings]
    );

    const resetErrors = () =>
    {
        const errors = { ...errorsList };
        Object.values(inputMappings).forEach(inputMapping => {
            if (inputMapping.outputName !== "") {
                errors.inputMappings[inputMapping.inputName] = [];
            }
        });
        setErrorsList(errors);
    };

    const initializeState = () =>
    {
        const endComponent = onBoardComponents[connection.end.gridItem];
        const inputMappings = endComponent.configData.inputMappings || {};
        endComponent.component.input.forEach((input) =>
            inputMappings[input.machineName] = {
                sourceComponentId:
                    inputMappings[input.machineName]?.sourceComponentId || "",
                inputName: input.machineName,
                title: input.title,
                description: input.description,
                argumentConversionType: "CAST",
                argumentMappingType: "OUTPUT_TO_INPUT",
                outputName: inputMappings[input.machineName]?.outputName || "",
                value: null
            }
        );

        setAvailableOutputs(props.getUpstreamOutputs(endComponent));
        setInputMappings(inputMappings);
        connection.buttonName && setButtonName(connection.buttonName);
    };

    const onSave = () =>
    {
        if (isFormValid()) {
            const endComponent = {
                ...onBoardComponents[connection.end.gridItem]
            };
            endComponent.configData["inputMappings"] = inputMappings;
            endComponent.hasInputMappingsCompleted = true;
            const componentsToUpdate = [endComponent];

            if (buttonName && buttonName !== connection.buttonName) {
                const updatedConnection = { ...connection };
                updatedConnection["buttonName"] = buttonName;
                const startComponent =
                    { ...onBoardComponents[updatedConnection.start.gridItem] };
                startComponent.outgoingConnections =
                    endComponent.incomingConnections = [updatedConnection];
                componentsToUpdate.push(startComponent);
            }

            updateAndResetSidebar({ componentsToUpdate: componentsToUpdate });
            setButtonName("");
        }
    };

    const isFormValid = () =>
    {
        const errors = { ...errorsList };
        let isValid = true;
        Object.values(inputMappings).forEach(mapping => {
            if (mapping.outputName === "") {
                errors.inputMappings[mapping.inputName] = [MANDATORY_ERROR];
                isValid = false;
            }
        });
        setErrorsList(errors);

        return isValid;
    };

    const onDelete = () =>
    {
        props.setupModal(
            () => props.deleteConnection(connection),
            <>
                <p className="modal-title">
                    Are you sure you want to delete the connection?
                </p>
                <p className="modal-description">
                    You will lose all the input mappings for the connected
                    component
                </p>
            </>
        );
        updateAndResetSidebar();
        setButtonName("");
    };

    const onClose = () =>
    {
        updateAndResetSidebar();
        setButtonName("");
    };

    const handleInputsChange = (e, input, index) =>
    {
        const sourceComponentId =
            document.querySelector(`#${index} option:checked`).parentElement.id;
        const outputName =
            availableOutputs[sourceComponentId]?.[e.target.value]?.machineName;

        setInputMappings((prevState) => ({
            ...prevState,
            [input.inputName]: {
                ...prevState[input.inputName],
                outputName: outputName,
                sourceComponentId: sourceComponentId
            }
        }));
    };

    const getMatchingOutputs = (input) => Object.entries(availableOutputs)
        .map(([componentId, outputs]) => {
            const name = onBoardComponents[componentId].component.displayName;

            return (
                <optgroup
                    key={ componentId }
                    label={ name + " outputs:" }
                    id={ componentId }
                >
                    {
                        Object.entries(outputs).map(([outputId, output]) =>
                            ioTypesCanBeConverted(output.type, input.type) &&
                            <option key={ outputId } value={ outputId }>
                                { output.title }
                            </option>
                        )
                    }
                </optgroup>
            );
        });

    const setSelectedValue = (input) =>
    {
        if (!availableOutputs[input.sourceComponentId]) {
            return "";
        }
        const output = Object.entries(availableOutputs[input.sourceComponentId])
            .find(([outputId, output]) =>
                input.outputName === output.machineName && outputId
            );

        return output[0];
    };

    const displayInput = (input, index) => (
        <div key={ index }>
            <div className={ "form-group " + getClassName(input.inputName) }>
                <label className="form-label">
                    { input.title }
                </label>
                <select
                    onChange={
                        e => handleInputsChange(e, input, "select" + index)
                    }
                    className="form-control"
                    id={ "select" + index }
                    value={ setSelectedValue(input) }
                >
                    <option value="">Select</option>
                    { getMatchingOutputs(input) }
                </select>
            </div>
            {
                input.inputName in errorsList.inputMappings &&
                errorsList.inputMappings[input.inputName].map(displayError)
            }
        </div>
    );

    const displayError = (error, index) =>
        <div key={ index } className="error-message">{ error }</div>;

    const getClassName = (inputName) =>
        errorsList.inputMappings[inputName]?.length > 0 ? "not-valid" : "";

    const generateConnectionInfo = () =>
    {
        const startComponent = onBoardComponents[connection.start.gridItem];
        const endComponent = onBoardComponents[connection.end.gridItem];

        return (
            <div>
                <small className="section-subtitle">Start point:</small>
                <p className="section-subtitle ml-1">
                    { startComponent.component.name }
                </p >
                <small className="section-subtitle m-0">End point:</small>
                <p className="section-subtitle">
                    { endComponent.component.name }
                </p>
                <div>
                    {
                        endComponent.component.input.length > 0 &&
                        <div>
                            <small className="section-subtitle">
                                Configure inputs:
                            </small>
                            { Object.values(inputMappings).map(displayInput) }
                        </div>
                    }
                </div>
            </div>
        );
    };

    const form = () => (
        <form>
            <div className="form-group">
                <label className="form-label">
                    &quot;Continue to...&quot; button name:
                </label>
                <input
                    type="text"
                    className="form-control"
                    onChange={ e => setButtonName(e.target.value) }
                    value={ buttonName }
                    placeholder="Enter custom text here"
                />
            </div>
            <div className="actions">
                <button
                    type="button" className="btn cancel-btn" onClick={ onClose }
                >
                    Close
                </button>
                <button className="btn button" type="button" onClick={ onSave }>
                    Done
                </button>
            </div>
        </form>
    );

    return (
        <Drawer
            variant={ "persistent" }
            anchor={ "right" }
            open={ props.sidebarOpened }
            className="connection-sidebar"
        >
            <button
                className="btn delete-btn" type="button" onClick={ onDelete }
            >
                Delete connection
            </button>
            <div className="customize-sidebar">
                <h5 className="section-title">Connection info:</h5>
                { connection && generateConnectionInfo() }
                { form() }
            </div>
        </Drawer>
    );
};

export default CustomizeConnectionSidebar;
