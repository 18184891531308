import React from "react";

const OwnedItemList = (props) => (
    <div>
        {
            props.items.map((item) => (
                <div key={ item.id } className="item">
                    <div className="item-title">
                        <span>{ item.name }</span>
                    </div>
                    {
                        Object.entries(item.data).map(([key, value]) => (
                            <div className="item-property" key={ key }>
                                <span>{ key } :</span>
                                <span>{ value }</span>
                            </div>
                        ))
                    }
                </div>
            ))
        }
    </div>
);

export default OwnedItemList;