import {
    checkApiResponse,
    get,
    patch,
    post
} from "../../_common/services/webClient";
import { UserNotFound } from "../errors/UserNotFound";
import { configurations } from "../../_common/configurations";

const USERS_API = configurations.apiUrl + "/users";

export const signIn = (address, signature) => post(
    `${configurations.apiUrl}/auth/login`,
    { username: address, signature: signature }
);

export const signOut = (address) => post(
    `${configurations.apiUrl}/auth/logout`, { username: address }
);

export const fetchUserNonce = (address) => getUserNonce(address)
    .catch(() => registerUser(address).then(user => user.nonce))
    .catch(error => {
        throw new UserNotFound(error.message);
    });

export const getUser = () => get(`${USERS_API}/me`).then(checkApiResponse)
    .then(response => response.json());

export const updateUserStatus = (currentStatus, user, setUser) => setUser({
    username: user.username,
    name: user.name,
    email: user.email,
    newUser: user.newUser,
    declaredPackages: user.declaredPackages,
    status: currentStatus.status
});

export const updateUser = (dto) => patch(`${USERS_API}/me`, dto)
    .then(checkApiResponse);

const getUserNonce = (address) => get(`${USERS_API}/${address}/nonce`)
    .then(response => handleResponse(response, "Nonce not found"));

const registerUser = (address) => post(USERS_API, { username: address })
    .then(response => handleResponse(response, "Registration failed"));

const handleResponse = (response, message) =>
{
    if (response.ok) {
        return response.json();
    }

    throw { message: message };
};
