import React from "react";

export const buildComponentErrorMessage = (message1, components, message2) =>
    <div>
        { message1 }
        <ul>
            {
                components.map(component =>
                    <li key={component.id}>
                        {component.displayName}
                    </li>
                )
            }
        </ul>
        { message2 }
    </div>;

export const ioTypesCanBeConverted = (outputType, inputType) =>
    !(outputType === "string" && inputType === "numeric");
