import React from "react";

const EnableIcon = () =>
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" 
            viewBox="0 0 15 15">
            <path id="Icon_ionic-ios-checkbox" 
                data-name="Icon ionic-ios-checkbox" 
                d="M18.25,4.5H5.75A1.249,1.249,0,0,0,4.5,5.75v12.5A1.249,
                    1.249,0,0,0,5.75,19.5h12.5a1.249,1.249,0,0,0,
                    1.25-1.25V5.75A1.249,1.249,0,0,0,18.25,4.5ZM16.2,
                    9.754,10.977,15h0a.705.705,0,0,1-.453.215.684.684,0,0,
                    1-.457-.223L7.875,12.8a.156.156,0,0,1,0-.223l.7-.7a.151.151,
                    0,0,1,.219,0l1.734,1.734,4.766-4.8a.154.154,0,0,
                    1,.109-.047h0a.142.142,0,0,1,.109.047l.684.707A.154.154,0,
                    0,1,16.2,9.754Z" transform="translate(-4.5 -4.5)" 
                fill="#a5c100"/>
        </svg>
    );

export default EnableIcon;