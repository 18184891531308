import { DEFAULT_HEADERS } from "../constants/clientConstants";
import { Unauthorized } from "../../user/errors/Unauthorized";
import { UnknownError } from "../../user/errors/UnknownError";
import { Conflict } from "../../user/errors/Conflict";
import { BadRequest } from "../../user/errors/BadRequest";
import { PaymentRequired } from "../../user/errors/PaymentRequired";

export const post = (
    url,
    body,
    headers = DEFAULT_HEADERS,
    credentials = "include"
) => fetch(
    url,
    {
        body: JSON.stringify(body),
        headers: headers,
        credentials: credentials,
        method: "POST"
    }
);

export const put = (
    url,
    body,
    headers = DEFAULT_HEADERS,
    credentials = "include"
) => fetch(
    url,
    {
        body: JSON.stringify(body),
        headers: headers,
        credentials: credentials,
        method: "PUT"
    }
);

export const get = (url, headers = DEFAULT_HEADERS, credentials = "include") =>
    fetch(url, { headers: headers, credentials: credentials, method: "GET" });

export const patch = (
    url,
    body,
    headers = DEFAULT_HEADERS,
    credentials = "include"
) => fetch(
    url,
    {
        body: JSON.stringify(body),
        headers: headers,
        credentials: credentials,
        method: "PATCH"
    }
);

export const del = (
    url,
    body,
    headers = DEFAULT_HEADERS,
    credentials = "include"
) => fetch(
    url,
    {
        body: JSON.stringify(body),
        headers: headers,
        credentials: credentials,
        method: "DELETE"
    }
);

export const checkApiResponse = (response) =>
{
    if (response.ok) {
        return response;
    } else if (response.status === 400) {
        throw new BadRequest(response.json());
    } else if (response.status === 402) {
        throw new PaymentRequired(response.json());
    } else if (response.status === 403) {
        throw new Unauthorized();
    } else if (response.status === 409) {
        throw new Conflict();
    } else {
        throw new UnknownError(response.text());
    }
};
