export const configurations = process.env.NODE_ENV === "production" ?
    window.runtimeConfiguration :
    {
        apiUrl: process.env.REACT_APP_API_URL,
        iblAddress: process.env.REACT_APP_IBL_ADDRESS,
        iblErc20Address: process.env.REACT_APP_IBL_ERC20_ADDRESS,
        defaultChainId: process.env.REACT_APP_DEFAULT_CHAIN_ID,
        infrastructureFee: process.env.REACT_APP_INFRASTRUCTURE_FEE,
        iblDevAccount: process.env.REACT_APP_IBL_DEV_ACCOUNT,
        endUserAppDomain: process.env.REACT_APP_END_USER_APP_DOMAIN
    };
