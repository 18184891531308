import React from "react";
import { TAB_FEES, TAB_GENERAL_DETAILS } from "../constants/tabs";

const ActionButtons = (props) => (
    <div className="actions">
        <button
            type="button"
            className="btn cancel-btn"
            onClick={ props.handleCancel }
        >
            Cancel
        </button>
        <div>
            <button
                className="btn cancel-btn"
                type="button"
                hidden={ props.tab === TAB_GENERAL_DETAILS }
                onClick={ props.handleClickPrevious }
            >
                Previous step
            </button>
            <button
                className="btn button"
                type="button"
                hidden={
                    props.tab !== TAB_FEES ||
                    props.isPublished ||
                    !props.canBePublished
                }
                onClick={ props.handlePublish }
            >
                Save & Publish
            </button>
            <button
                className="btn button"
                type="submit"
                hidden={
                    props.tab !== TAB_FEES ||
                    !(props.isPublished || !props.canBePublished)
                }
            >
                Save
            </button>
            <button
                className="btn button"
                type="button"
                onClick={ props.handleClickNext }
                hidden={ props.tab === TAB_FEES }
            >
                Next
            </button>
        </div>
    </div>
);

export default ActionButtons;
