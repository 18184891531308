import React, { useState } from "react";
import Icon from "../../_common/components/Icon";
import CostEstimation from "../../_common/components/CostEstimation";
import MagicWand from "../../_common/assets/images/MagicWand";
import ConfirmModal from "../../_common/notifications/ConfirmModal";
import { ethers } from "ethers";
import { COST_APPROX } from "../../_common/constants/notifications";

const IdeMenu = (props) =>
{
    const [showDraftModal, setShowDraftModal] = useState(false);
    const [isHoveringDownload, setIsHoveringDownload] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [isHoveringPublish, setIsHoveringPublish] = useState(false);
    const [showPublishModal, setShowPublishModal] = useState(false);

    const TOOLTIP_TEXT = "The amount you want to receive from every final" +
        " user when they are using your application";

    const renderCostEstimation = (paymentType) => (
        <CostEstimation
            components={ props.unpaidComponents }
            extraFees={ props.extraFees }
            paymentType={ paymentType }
        />
    );

    const renderPriceConfirm = (paymentType) => (
        <>
            <p className="modal-title">
                { paymentType === "runPrice" ? "Publish " : "Download " }
                Application
            </p>
            <p className="modal-description">
                { COST_APPROX }
            </p>
            { renderCostEstimation(paymentType) }
            <div className="item total-cost">
                <div className="item">
                    <span>
                        { "Total cost for " + props.application.name }
                    </span>
                    <span className="value">
                        { formatEther(props.getFeesTotal(paymentType)) }
                    </span>
                </div>
            </div>
        </>
    );

    const handleAppFeeChange = (e) => props.setChangedAppFee(e.target.value);

    const formatEther = (price) => ethers.utils.formatEther(price);

    return (
        <div className="drawing-board-menu">
            <div className="menu--left">
                <button
                    className="btn"
                    type="button"
                    onClick={ props.handleGoBack }
                >
                    <Icon name="arrowLeft"/>
                    <span>Back</span>
                </button>
                <div className="app-details">
                    <p>{ props.application.name }</p>
                    <span>&bull;</span>
                    <small>{ props.application.status }</small>
                </div>
            </div>
            <div className="menu--right">
                <div className="app-fee">
                    <div className="user-fee">
                        <label>
                            User fee
                        </label>
                        <label>
                            <Icon
                                name="info_tooltip"
                                className="tooltip-icon"
                                size="lg"
                                data-toggle="tooltip"
                                data-placement="right"
                                title={ TOOLTIP_TEXT }
                            />
                        </label>
                    </div>
                    <div className="fee-input">
                        <div className="input-with-button">
                            <input
                                className="form-control"
                                value={ props.changedAppFee }
                                onChange={ handleAppFeeChange }
                                name="userFee"
                                type="number"
                                step="any"
                                min="0"
                                placeholder="User fee"
                            />
                            <button
                                className="btn change-fee-btn"
                                disabled={ !props.appFeeAltered() }
                                onClick={ props.changeAppFee }
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                <div className="download-btn--container">
                    <button
                        className="btn action-btn download-btn"
                        onClick={ () => setShowDownloadModal(true) }
                        disabled={ !props.isApplicationComplete }
                        onMouseOver={ () => setIsHoveringDownload(true) }
                        onMouseOut={ () => setIsHoveringDownload(false) }
                    >
                        <span>Download</span>
                        <Icon name="download" />
                        <span className="estimated-cost">
                            { formatEther(props.getFeesTotal("downloadPrice")) }
                        </span>
                    </button>
                    {
                        isHoveringDownload &&
                            renderCostEstimation("downloadPrice")
                    }
                </div>
                <div className="publish-btn--container">
                    <button
                        className="btn action-btn publish-btn"
                        onClick={ () => setShowPublishModal(true) }
                        disabled={ !props.isApplicationComplete }
                        onMouseOver={ () => setIsHoveringPublish(true) }
                        onMouseOut={ () => setIsHoveringPublish(false) }
                    >
                        <span>Publish</span>
                        <MagicWand />
                        <span className="estimated-cost">
                            { formatEther(props.getFeesTotal("runPrice")) }
                        </span>
                    </button>
                    {
                        isHoveringPublish &&
                            renderCostEstimation("runPrice")
                    }
                </div>
                <button
                    className="btn action-btn discard-btn"
                    type="button"
                    onClick={ () => setShowCancelModal(true) }
                >
                    <span>Discard</span>
                    <Icon name="trash" />
                </button>
                <button
                    className="btn save-app-btn" onClick={
                        () => props.handleSave(() => setShowDraftModal(true))
                    }
                >
                    <span>Save</span>
                </button>
                <ConfirmModal
                    show={ showCancelModal }
                    setShow={ setShowCancelModal }
                    message={
                        <p className="modal-title">
                            Are you sure you want to cancel the changes made on
                            the page?
                        </p>
                    }
                    handleConfirm={ props.handleCancel }
                />
                <ConfirmModal
                    show={ showDraftModal }
                    setShow={ setShowDraftModal }
                    message={
                        <p className="modal-title">
                            We encountered some errors, do you want to save as
                            a draft?
                        </p>
                    }
                    handleConfirm={ props.handleSaveAsDraft }
                />
                <ConfirmModal
                    customClassName="cost-modal"
                    show={ showDownloadModal }
                    setShow={ setShowDownloadModal }
                    message={ renderPriceConfirm("downloadPrice") }
                    handleConfirm={ props.handleDownloadApplication }
                    confirmButtonText="Pay & Download app"
                    cancelButtonText="Back"
                    reversed="true"
                />
                <ConfirmModal
                    customClassName="cost-modal"
                    show={ showPublishModal }
                    setShow={ setShowPublishModal }
                    message={ renderPriceConfirm("runPrice") }
                    handleConfirm={ props.handleDeployApplication }
                    confirmButtonText="Pay & Publish app"
                    cancelButtonText="Back"
                    reversed="true"
                />
            </div>
        </div>
    );
};

export default IdeMenu;
