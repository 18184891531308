import React from "react";
import { Modal, Box } from "@mui/material";
import { BOX_STYLE } from "../constants/messageStyles";
import "./modal-style.sass";

const ConfirmModal = (props) => (
    <Modal
        open={ props.show }
        onClose={ () => props.setShow(false) }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={ `ibl-modal ` + props.customClassName }
    >
        <Box className="modal-body" sx={ BOX_STYLE }>
            <div className="modal-message">{ props.message }</div>
            <div className={ `actions ${ props.reversed ? "reversed" : "" }` }>
                <button className="btn button yes-btn" onClick={() => {
                    props.setShow(false);
                    props.handleConfirm();
                }}>
                    { props.confirmButtonText || "Yes" }
                </button>
                <button
                    className="btn button no-btn"
                    onClick={ () => props.setShow(false) }
                >
                    { props.cancelButtonText || "No" }
                </button>
            </div>
        </Box>
    </Modal>
);

export default ConfirmModal;