import React, { useContext, useState, useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { CURRENT_USER } from "../../_common/context";
import {
    IDE_APPLICATION,
    LOGIN_ROUTE,
    APPLICATIONS
} from "../../_common/constants/routes";
import {
    updateApplication,
    saveDraft,
    createDraft,
    fetchApplicationVersion
} from "../clients/applicationClient";
import { getImage, uploadImage } from "../../_common/services/imageClient";
import { configurations } from "../../_common/configurations";
import { getThumbnailUri } from "../../_common/services/imageHelper";
import {
    MANDATORY_ERROR,
    GENERIC_ERROR,
    THEME_NOT_FOUND,
    DOMAIN_IS_ALREADY_TAKEN,
    INVALID_DOMAIN
} from "../../_common/constants/errors";
import "./applicationForm.sass";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../_common/constants/messageStyles";
import upload from "../../_common/assets/images/file-upload.svg";
import { fetchLatestVersion, fetchThemes } from "../clients/themeClient";
import ConfirmModal from "../../_common/notifications/ConfirmModal";
import {
    catchUnauthorized,
    removeErrorWhenTyping
} from "../../_common/helper/functions";
import { BadRequest } from "../../user/errors/BadRequest";
import {
    APPLICATION_SAVED,
    SAVED_AS_DRAFT
} from "../../_common/constants/notifications";
import { Conflict } from "../../user/errors/Conflict";

const INVALID_FILE_FORMAT_ERROR = "File format is not valid. " +
    "Please choose a valid image file";
const INVALID_FILE_SIZE_ERROR = "The image can’t be uploaded because it" +
    " exceeds the size limit of 5 MB. Please upload a smaller image";
const VALID_IMAGE_EXTENSIONS = ["jpg", "jpeg", "bmp", "png", "tif"];

const defaultApplication = {
    name: "",
    metaDescription: "",
    domain: null,
    metaTitle: "",
    deploymentType: "IBL_HOSTED",
    logoId: "",
    processes: [],
    themeId: "",
    domainModifiable: true
};

const DOMAIN_REGEX = /^[a-z]([-a-z0-9]*[a-z0-9])*$/;

const ApplicationForm = () =>
{
    const { id } = useParams();
    const { user, setUser } = useContext(CURRENT_USER);
    const navigate = useNavigate();
    !user.username && navigate(LOGIN_ROUTE);

    const initialErrors = { name: [], themeId: [], domain: [] };
    const [application, setApplication] = useState(defaultApplication);
    const [logo, setLogo] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const [errorsList, setErrorsList] = useState(initialErrors);
    const [themes, setThemes] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formHasChanges, setFormHasChanges] = useState(false);
    const [formIsInitialized, setFormIsInitialized] = useState(false);
    const [createdAppId, setCreatedAppId] = useState("");

    useEffect(
        () => {
            fetchThemes()
                .then((themes) => {
                    setThemes(themes);
                    id ? initializeForm(id) : setFormIsInitialized(true);
                })
                .catch((e) => catchUnauthorized(e, setUser));
        },
        []
    );
    useEffect(() => { logo && uploadApplicationLogo(); }, [logo]);
    useEffect(
        () => removeErrorWhenTyping(errorsList, setErrorsList, "name"),
        [application.name]
    );
    useEffect(
        () => removeErrorWhenTyping(errorsList, setErrorsList, "themeId"),
        [application.themeId]
    );
    useEffect(
        () => { formIsInitialized && setFormHasChanges(true); },
        [application]
    );

    const initializeForm = (id) => fetchApplicationVersion(id)
        .then((application) => {
            setApplication(application);
            setLatestTheme(application.themeId).then(
                () => !application.logoId ?
                    setTimeout(() => setFormIsInitialized(true)) :
                    getImage(application.logoId)
                        .then((image) => setLogoUrl(imageUrl(image)))
                        .then(() => setFormIsInitialized(true))
                        .catch((e) => catchUnauthorized(e, setUser))
            );
        })
        .catch((e) => catchUnauthorized(e, setUser));

    const setLatestTheme = (themeId) =>
        themes.map(theme => theme.id).includes(themeId) ?
            new Promise(() => setThemeId(themeId)) :
            fetchLatestVersion(themeId).then(theme => setThemeId(theme.id))
                .catch(() => {
                    setThemeId("");
                    toast.info(THEME_NOT_FOUND, TOAST_SETTINGS);
                });

    const setThemeId = (id) =>
        setApplication(prevState => ({ ...prevState, themeId: id }));

    const onChange = (e) => setApplication(
        prevState => ({ ...prevState, [e.target.name]: e.target.value })
    );

    const uploadApplicationLogo = () => isValidLogo(logo) && uploadImage(logo)
        .then(image => {
            setApplication(prevState => ({ ...prevState, logoId: image.id }));
            setLogoUrl(imageUrl(image));
        })
        .catch((e) => {
            catchUnauthorized(e, setUser);
            toast.error("Error. Image was not uploaded", TOAST_SETTINGS);
        });

    const showToastAndNavigate = (message, appId) =>
    {
        navigate(generatePath(IDE_APPLICATION, { id: appId }));
        toast.success(message, TOAST_SETTINGS);
    };

    const handleSubmit = (event) =>
    {
        event.preventDefault();
        !formHasChanges && application.id &&
            showToastAndNavigate(APPLICATION_SAVED, application.id);

        if (formHasChanges && checkFormValidityAndSetError()) {
            const savePromise = application.status !== "DRAFT" && id ?
                updateApplication(application.applicationId, application)
                    .then((response) => response.json())
                    .then((response) => () => showToastAndNavigate(
                        APPLICATION_SAVED, response.id
                    ))
                    .catch((e) => {
                        catchUnauthorized(e, setUser);
                        e instanceof BadRequest ?
                            saveAsDraft(application, id)
                                .then((response) => () => showToastAndNavigate(
                                    SAVED_AS_DRAFT, response.id
                                )) :
                            handleConflictAndGenericError(e);
                    }) :
                saveAsDraft(application, application?.applicationId || null)
                    .then((response) => () => showToastAndNavigate(
                        SAVED_AS_DRAFT, response.id
                    ));

            savePromise.then && savePromise.then((callable) => callable());
        }
    };

    const saveAsDraft = (application, id) =>
    {
        const appId = id || createdAppId;
        const saveOrCreateDraft = appId ?
            saveDraft(appId, application).then((response) => response.json()) :
            createDraft(application).then((response) => response.json())
                .then((response) => fetchApplicationVersion(response.id)
                    .then((application) =>
                        setCreatedAppId(application.applicationId)
                    )
                    .then(() => response)
                );

        return saveOrCreateDraft.catch((e) => {
            catchUnauthorized(e, setUser);
            handleConflictAndGenericError(e);
        });
    };

    const removeApplicationLogo = () =>
    {
        setApplication({ ...application, logoId: "" });
        setLogoUrl("");
    };

    const getClassName = (field) =>
        "form-control" + (errorsList[field].length > 0 ? " not-valid" : "");

    const checkFormValidityAndSetError = () =>
    {
        const errors = { ...errorsList };
        let isValid = true;

        const setError = (field, error) =>
        {
            errors[field].indexOf(error) === -1 && errors[field].push(error);
            isValid = false;
        };

        ["name", "themeId"].filter(field => !application[field])
            .forEach((field) => setError(field, MANDATORY_ERROR));

        const domain = application.domain;
        if (domain && (!DOMAIN_REGEX.test(domain) || /^\d/.test(domain))) {
            setError("domain", INVALID_DOMAIN);
        }

        !isValid && setErrorsList({ ...errors });

        return isValid;
    };

    return (
        <div className="application-form">
            <form onSubmit={ handleSubmit }>
                <div className="form-heading">
                    <h2>Create / Edit IBL program</h2>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <div className="mb-3" key="name">
                            <label className="form-label required">
                                Application name
                            </label>
                            <input
                                type="text"
                                name="name"
                                className={ getClassName("name") }
                                onChange={ onChange }
                                placeholder="Application title"
                                value={ application.name || "" }
                            />
                            { errorsList.name.map(displayError) }
                        </div>
                        <div className="mb-3" key="domain">
                            <label className="form-label">Subdomain</label>
                            <div className="input-group">
                                <input
                                    disabled={ !application.domainModifiable }
                                    name="domain"
                                    type="text"
                                    className="form-control"
                                    onChange={ onChange }
                                    value={ application.domain || "" }
                                    placeholder="your app"
                                />
                                <span className="input-group-text">
                                    .ibl.com
                                </span>
                                { errorsList.domain.map(displayError) }
                            </div>
                            <small
                                className="mt-2"
                                hidden={ application.status === "PUBLISHED" }
                            >
                                This will be needed in order to publish your
                                application
                            </small>
                        </div>
                        <div key="logo">
                            <label className="form-label mb-2">
                                Application logo
                            </label>
                            <input
                                id="image-select"
                                type="file"
                                hidden={ true }
                                accept=".jpg,.jpeg,.bmp,.png,.tif"
                                className="form-control"
                                onChange={ e => setLogo(e.target.files[0]) }
                            />
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="mb-3" key="metaDescription">
                            <label className="form-label">
                                Meta description
                            </label>
                            <input
                                className="form-control"
                                name="metaDescription"
                                onChange={ onChange }
                                value={ application.metaDescription || "" }
                                placeholder="Meta description..."
                            />
                        </div>
                        <div className="mb-3" key="themeId">
                            <label className="form-label required">
                                Define colour theme
                            </label>
                            <select
                                name="themeId"
                                className={ getClassName("themeId") }
                                onChange={ onChange }
                                value={ application.themeId }
                            >
                                <option value="">-- Select --</option>
                                { themes.map(displayThemeOption) }
                            </select>
                            { errorsList.themeId.map(displayError) }
                        </div>
                    </div>
                </div>
                <div className="mb-3" key="preview">
                    {
                        !logoUrl ?
                            <div>
                                <small>no image slected</small>
                                <label htmlFor="image-select">
                                    <span className="upload-image">
                                        Upload image
                                    </span>
                                    <img
                                        className="m-1"
                                        src={ upload }
                                        alt={ "logo" }
                                    />
                                </label>
                                <small>jpg, jpeg, bmp, png, tif</small>
                            </div> :
                            <div>
                                <img src={ logoUrl } alt="No Preview"/>
                                <label
                                    className="mt-2 change-image"
                                    htmlFor="image-select">
                                    Change image
                                </label>
                                <button
                                    className="btn remove-btn"
                                    type="button"
                                    onClick={ removeApplicationLogo }
                                >
                                    Remove uploaded image
                                </button>
                            </div>
                    }
                </div>

                <button className="btn button btn-submit" type="submit">
                    Save and continue
                </button>
                <button
                    className="btn cancel-btn"
                    type="button"
                    onClick={ () => setShowConfirmModal(true) }
                >
                    Cancel
                </button>
                <ConfirmModal
                    show={ showConfirmModal }
                    setShow={ setShowConfirmModal }
                    message={
                        <p className="modal-title">
                            Are you sure you want to cancel the changes made on
                            the page?
                        </p>
                    }
                    handleConfirm={ () => navigate(APPLICATIONS) }
                />
            </form>
        </div>
    );
};

const displayThemeOption = (theme) =>
    <option key={ theme.id } value={ theme.id }>{ theme.name }</option>;

const displayError = (error, index) =>
    <div key={ index } className="error-message">{ error }</div>;

const isValidLogo = (logo) =>
{
    const logoExtension = /(?:\.([^.]+))?$/.exec(logo.name)[1];
    let isValid = true;

    if (!VALID_IMAGE_EXTENSIONS.includes(logoExtension)) {
        toast.error(INVALID_FILE_FORMAT_ERROR, TOAST_SETTINGS);
        isValid = false;
    } else if (logo.size / 1000 > 5000) {
        toast.error(INVALID_FILE_SIZE_ERROR, TOAST_SETTINGS);
        isValid = false;
    }

    return isValid;
};

const handleConflictAndGenericError = (e) => e instanceof Conflict ?
    toast.error(DOMAIN_IS_ALREADY_TAKEN, TOAST_SETTINGS) :
    toast.error(GENERIC_ERROR, TOAST_SETTINGS);

const imageUrl = (image) => configurations.apiUrl + getThumbnailUri(image);

export default ApplicationForm;
