import React, { useContext } from "react";
import ConfirmModal from "./ConfirmModal";
import feLibrary from "../services/FrontEndLibrary";
import { catchUnauthorized } from "../helper/functions";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../constants/messageStyles";
import { CURRENT_USER } from "../context";
import {
    PAYMENT_PROCESSING
} from "../constants/notifications";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "../constants/routes";
import { INPUT_MAXLENGTH } from "../../component/constants/maxInputLength";
import { ethers } from "ethers";
import { GENERIC_ERROR, REJECTED_PAYMENT } from "../constants/errors";
import { PaymentRequired } from "../../user/errors/PaymentRequired";
import { registerPayment } from "../payment/paymentClient";

const PublishModal = (props) => {
    const component = props.component;
    const navigate = useNavigate();
    const { user, setUser } = useContext(CURRENT_USER);

    const onPaymentProcessing = () =>
    {
        props.setIdToUpdate && props.setIdToUpdate(component.id);
        toast.info(PAYMENT_PROCESSING, TOAST_SETTINGS);
        navigate(DASHBOARD, { state : { componentId: component.id }});
    };

    const doPublishComponent = () => {
        const contractComponent = { ...component };
        delete contractComponent.displayName;

        feLibrary.addComponentToContract(
            user.username, contractComponent, onPaymentProcessing
        )
            .then(serializedTx => registerPayment({
                paidObjectId: component.id,
                type: "COMPONENT",
                hexTransaction: serializedTx,
                value: contractComponent.downloadPrice.toString()
            }))
            .catch((e) => {
                if (e instanceof PaymentRequired) {
                    e.error.then(e => toast.error(
                        e.detail + ": " + e.violations?.transaction,
                        TOAST_SETTINGS
                    ));
                } else {
                    return e.message === "REJECTED_PAYMENT" ?
                        toast.error(REJECTED_PAYMENT, TOAST_SETTINGS) :
                        toast.error(GENERIC_ERROR, TOAST_SETTINGS);
                }
                catchUnauthorized(e, setUser);
                props.onCloseModal();
            });
    };

    const renderInput = (priceType) => (
        <input
            type="number"
            min="0"
            step="any"
            className="form-control"
            placeholder="Download price"
            defaultValue={
                component[priceType] ?
                    ethers.utils.formatEther(component[priceType]) : ""
            }
            maxLength={ INPUT_MAXLENGTH }
            onChange={
                (e) => props.setPrice(e.target.value, priceType)
            }
        />
    );

    const renderFeesInput = () => (
        <p className="modal-fees">
            <p className="modal-description">
                Before publishing a component you must define
                the Run and Download prices. The component publishing price
                will be the value of the download price.
            </p>
            <label className="modal-label">Run price (ETH)</label>
            { renderInput("runPrice") }
            <label className="modal-label">Download price (ETH)</label>
            { renderInput("downloadPrice") }
        </p>
    );

    const renderFees = () => (
        <div className="cost-container">
            <p className="modal-description">
                The component publishing price
                is the value of the download price.
            </p>
            <div className="item">
                <span className="label">Run price</span>
                <span className="value">
                    { formatPrice(component.runPrice) }
                </span>
            </div>
            <div className="item item-last">
                <span className="label">Download price</span>
                <span className="value">
                    { formatPrice(component.downloadPrice) }
                </span>
            </div>
        </div>
    );

    const modalMessage = () => (
        <>
            <p className="modal-title">Publish Component</p>
            { props.showSetFees ? renderFeesInput() : renderFees() }
            <div className="item total-cost">
                <div className="item">
                    <span>
                        Publication costs for the
                        &#34;{ component.displayName }&#34; component:
                    </span>
                    <span className="value">
                        { formatPrice(component.downloadPrice) }
                    </span>
                </div>
            </div>
        </>
    );

    const formatPrice = (value) => value ?
        ethers.utils.formatEther(value) + " ETH" : "define component fees";

    return (
        <ConfirmModal
            customClassName="cost-modal"
            show={ props.show }
            setShow={ props.setShow }
            message={ modalMessage() }
            handleConfirm={ doPublishComponent }
            confirmButtonText="Pay & Publish component"
            cancelButtonText="Back"
            reversed="true"
        />
    );
};

export default PublishModal;
