import React from "react";
import { ethers } from "ethers";
import { FEE_GROUPS } from "../constants/feeGroups";
import { capitalizeString } from "../helper/functions";

const CostEstimation = (props) =>
{
    const getExtraFees = (paymentType) => Object.entries(props.extraFees)
        .filter((extraFee) => FEE_GROUPS[paymentType].includes(extraFee[0]));

    return (
        <div className="cost-container">
            {
                props.paymentType === "downloadPrice" &&
                    Object.entries(props.components).map(
                        ([componentId, componentData]) => (
                            <div className="item" key={ componentId }>
                                <span className="label">
                                    { componentData.displayName }
                                </span>
                                <span className="value">
                                    {
                                        ethers.utils.formatEther(
                                            componentData.downloadPrice || 0
                                        )
                                    }
                                </span>
                            </div>
                        )
                    )
            }
            {
                getExtraFees(props.paymentType).map(([feeName, feeValue]) => (
                    <div className="item" key={ feeName }>
                        <span className="label">
                            { capitalizeString(feeName) + " fee" }
                        </span>
                        <span className="value">
                            {
                                feeValue ?
                                    ethers.utils.formatEther(feeValue) : ""
                            }
                        </span>
                    </div>
                ))
            }
        </div>
    );

};

export default CostEstimation;
