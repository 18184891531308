import React, { useState } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { CLAIM_ERROR } from "../../_common/constants/errors";
import { TOAST_SETTINGS } from "../../_common/constants/messageStyles";
import feLibrary from "../../_common/services/FrontEndLibrary";

const TabContent = (props) =>
{
    const [showSpinner, setShowSpinner] = useState(false);
    const [showAllowanceSpinner, setShowAllowanceSpinner] = useState(false);

    const handleOnClick = () =>
    {
        setShowSpinner(true);
        props.onActionClick()
            .then(() => {
                toast.success(
                    `Your ${props.actionButtonText} was successful`,
                    TOAST_SETTINGS
                );
                props.resetState();
            })
            .catch((error) => toast.error(
                <div>
                    <div>{ CLAIM_ERROR }</div>
                    <div>{ error.message }</div>
                </div>,
                TOAST_SETTINGS
            ))
            .finally(() => setShowSpinner(false));
    };

    const setAllowance = () =>
    {
        setShowAllowanceSpinner(true);
        feLibrary.approveTokens(props.data[1]?.value || "0")
            .then(() => {
                toast.success("Allowance updated", TOAST_SETTINGS);
                props.resetState();
            })
            .catch((error) => toast.error(error.message, TOAST_SETTINGS))
            .finally(() => setShowAllowanceSpinner(false));
    };

    const renderInsufficientAllowanceSection = () => (
        <div className="row">
            <div className="col">Insufficient allowance</div>
            <button
                className="btn button col"
                disabled={ showAllowanceSpinner }
                onClick={ setAllowance }
            >
                {
                    !showAllowanceSpinner ?
                        "Set" : <div className="button-spinner spinner-border"/>
                }
            </button>
        </div>
    );

    return (
        <div className="tab">
            <div className="row">
                {
                    props.data.map((item) => (
                        <div className="col" key={ item.label }>
                            <div className="label">{ item.label }</div>
                            <div className="value">
                                {
                                    ethers.utils.formatEther(item.value)
                                    + " IBL"
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="row no-gap">
                { props.renderInput() }
                <button
                    className="btn cancel-btn col"
                    onClick={ props.onMaxClick }
                >
                    MAX
                </button>
                <button
                    className="btn button col"
                    disabled={ showSpinner || props.noAllowance }
                    onClick={ handleOnClick }
                >
                    {
                        !showSpinner ? props.actionButtonText :
                            <div className="button-spinner spinner-border"/>
                    }
                </button>
            </div>
            { props.noAllowance && renderInsufficientAllowanceSection() }
        </div>
    );
};

export default TabContent;