import React from "react";

const Arrow = () =>
{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.001" height="20"
            viewBox="0 0 20.001 20">
            <path id="Subtraction_4" data-name="Subtraction 4"
                d="M-8506,290h-20V270h20v20Zm-16-12h0l6,6,6-6h-12Z"
                transform="translate(8526.001 -269.999)"/>
        </svg>
    );
};

export default Arrow;

