import { configurations } from "../configurations";
import { checkApiResponse, get } from "./webClient";

const IMAGE_API = configurations.apiUrl + "/image";

export const uploadImage = (image) =>
{
    if (!image) {
        return Promise.resolve("");
    }
    const file = new FormData();
    file.append("file", image, image.name);
    const fetchOptions = { body: file, credentials: "include", method: "POST" };

    return fetch(IMAGE_API, fetchOptions).then(checkApiResponse)
        .then(response => response.json());
};

export const getImage = (image) => get(`${IMAGE_API}/${image}`)
    .then(response => checkApiResponse(response))
    .then(response => response.json());

