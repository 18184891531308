import { ethers } from "ethers";
import { fetchUserNonce, signIn } from "../userApiCalls";
import { CONNECTED } from "../../constants/userStatus";
import { NoClientError } from "../../errors/NoClientError";
import { configurations } from "../../../_common/configurations";

const NO_ADDRESS_ERROR = `Connect to Metamask with the "Connect" button.`;
const TRANSACTION_RECEIPT_SUCCESS = "0x1";
const TRANSACTION_TYPE_2 = "0x2";

export const connectWallet = () => !window.ethereum ?
    Promise.reject(new NoClientError("You must install Metamask")) :
    window.ethereum.request({ method: "eth_requestAccounts" })
        .then((addresses) => {
            if (addresses.length <= 0) {
                throw { message: NO_ADDRESS_ERROR };
            }

            return fetchUserNonce(addresses[0])
                .then(data => new ethers.providers.Web3Provider(window.ethereum)
                    .getSigner()
                    .signMessage(`${data}`)
                )
                .then(signature => signIn(addresses[0], signature))
                .then(response => {
                    if (response.ok) {
                        return { address: addresses[0], status: CONNECTED };
                    }

                    throw { message: "Authentication failure" };
                });
        });

// this method is not used, but useful if we want the option to stay connected
export const getCurrentWalletConnected = () => !window.ethereum ?
    Promise.reject(new NoClientError("You must install Metamask")) :
    window.ethereum.request({ method: "eth_accounts" }).then(addresses => {
        if (addresses.length <= 0) {
            throw { message: NO_ADDRESS_ERROR };
        }

        return { address: addresses[0], status: CONNECTED };
    });

export const addWalletListener = (setWalletChanged) =>
    window.ethereum && window.ethereum.on(
        "accountsChanged", () => setWalletChanged(true)
    );

export const switchToDefaultNetwork = () =>
{
    const defaultChainId = configurations.defaultChainId;
    if (window.ethereum && window.ethereum.chainId !== defaultChainId) {
        return window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: defaultChainId }]
        }).catch((switchError) => {
            switchError.code === 4902 &&
                window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [{
                        chainId: defaultChainId,
                        chainName: "Sepolia test network",
                        rpcUrls:["https://sepolia.infura.io/v3/"],
                        blockExplorerUrls: ["https://sepolia.etherscan.io"],
                        nativeCurrency: {
                            symbol: "SepoliaETH",
                            decimals: 18
                        }
                    }]
                });

            return Promise.reject();
        });
    } else {
        return Promise.resolve();
    }
};

export const addNetworkListener = (setNetworkChange) =>
    window.ethereum && window.ethereum.on(
        "chainChanged", () => setNetworkChange(true)
    );

export const isDefaultNetwork = () =>
    window.ethereum.request({ method: "eth_chainId" })
        .then((chainId) => chainId === configurations.defaultChainId);

export const payInfrastructureFee = (userAddress) =>
{
    const value = ethers.utils
        .parseUnits(configurations.infrastructureFee,"ether");
    const transaction = {
        from: userAddress,
        to: configurations.iblDevAccount,
        value: value._hex,
        gas: "0x61A8"
    };

    return window.ethereum
        .request({ method: "eth_sendTransaction", params: [transaction] })
        .then(waitTransactionConfirmation)
        .then((tx) => {
            // TODO: S#itty way to do this.
            if (tx.type === TRANSACTION_TYPE_2) {
                tx.type = 2;
            }

            return ethers.utils.serializeTransaction(tx);
        })
        .catch(() => {
            throw new Error("Could not complete payment.");
        });
};

const waitTransactionConfirmation = (txHash) => window.ethereum
    .request({ method: "eth_getTransactionReceipt", params: [txHash] })
    .then(response => {
        if (response && response.status !== TRANSACTION_RECEIPT_SUCCESS) {
            throw new Error("Transaction failed");
        }

        return response ? getTransactionByHash(txHash) :
            waitTransactionConfirmation(txHash);
    });

const getTransactionByHash = (txHash) => window.ethereum
    .request({ method: "eth_getTransactionByHash", params: [txHash] });
