import React from "react";
import DisableIcon from "../../_common/assets/images/DisableIcon";
import EnableIcon from "../../_common/assets/images/EnableIcon";

const Card = (props) =>
{
    const item = props.item;
    const name = item.displayName || item.name;
    const date = new Date(item.updatedAt ?? item.createdAt);
    const displayDate = date.toLocaleDateString("en-GB");

    return (
        <div className={ `card ${ item.status.toLowerCase() }` }
            key={ item.id }>
            <div className="card-block">
                <div className="card-heading">
                    <h4 className="card-title">{ name ?? "No name set" }</h4>
                    { props.children }
                </div>
                <div className="card-body p-0">
                    <p className="card-text">Last update: { displayDate }</p>
                    <div className={ `status ${ item.status.toLowerCase() }` }>
                        {
                            displayStatus(
                                item.publishmentStatus === "FAILED" ?
                                    "FAILED" : item.status
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const displayStatus = (status) =>
{
    switch(status) {
        case "DISABLED":
            return <DisableIcon />;
        case "ENABLED":
            return <EnableIcon />;
        case "NOT_VALID":
            return <span>Incomplete</span>;
        case "TO_BE_PUBLISHED":
            return <span>Not published</span>;
        case "PUBLISHED":
            return <span>published</span>;
        case "NOT_PUBLISHED":
            return <span>not published</span>;
        case "FAILED":
            return <span>failed to publish</span>;
        case "DRAFT":
            return <span>Draft</span>;
        case "READY":
            return <span>Ready</span>;
        case "DOWNLOADED":
            return <span>Downloaded</span>;
    }
};

export default Card;
