import { checkApiResponse, get } from "../../_common/services/webClient";
import { configurations } from "../../_common/configurations";

const COMPONENTS_API = configurations.apiUrl + "/components";

export const getLatestComponents = () => get(COMPONENTS_API + "/latest")
    .then(checkApiResponse)
    .then(response => response.json());

export const getLatestVersion = (id) => get(COMPONENTS_API + `/${id}/latest`)
    .then(checkApiResponse)
    .then(response => response.json());
