import { configurations } from "../../_common/configurations";
import { checkApiResponse, get } from "../../_common/services/webClient";

const THEMES_API = configurations.apiUrl + "/themes";

export const fetchThemes = () => get(THEMES_API + "/latest")
    .then(response => response.json());

export const fetchLatestVersion = (id) => get(THEMES_API + `/${id}/latest`)
    .then(checkApiResponse)
    .then(response => response.json());
