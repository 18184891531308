import React from "react";

const HotspotButton = (props) =>
{
    const {
        gridItemKey,
        hotspotPosition,
        configuredComponent,
        handleConnection,
        connectionStartPoint,
        setConnectionStartPoint
    } = props;
    const id = gridItemKey + "_" + hotspotPosition;
    const selected = connectionStartPoint ?
        connectionStartPoint.node === id : false;

    const getHotspotClass = (position, selected) =>
        "hotspot hotspot-" + position + (selected ? " selected" : "");

    const handleClick = (e) => e.target.id === id && selected ?
        setConnectionStartPoint(null) : handleConnection(e, gridItemKey);

    const isButtonDisabled = () =>
    {
        // we agreed that the flow is linear (for now)
        // so only one outgoing and only one incoming connection is permitted
        // "start" component can have no incoming connection

        const hasOutput = configuredComponent.outgoingConnections.length > 0;
        const hasInput = configuredComponent.incomingConnections.length > 0;

        return selected ? false :
            (hasOutput && (hasInput || !connectionStartPoint)) ||
            (hasInput && connectionStartPoint);
    };

    return (
        <button
            type="button"
            id={ id }
            className={ getHotspotClass(hotspotPosition, selected) }
            onClick={ handleClick }
            disabled={ isButtonDisabled() }
        >
        </button>
    );
};

export default HotspotButton;
