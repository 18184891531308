export class PaymentRequired extends Error
{
    type = "PaymentRequiredException";
    error;

    constructor(error)
    {
        super();
        this.error = error;
    }
}