import { BadRequest } from "../../user/errors/BadRequest";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../_common/constants/messageStyles";
import { GENERIC_ERROR } from "../../_common/constants/errors";

export const handleError = (error) => error instanceof BadRequest ?
    error.error.then((e) => e.detail === "Invalid input." ?
        toast.error("Application is not complete", TOAST_SETTINGS) :
        toast.error(e.detail, TOAST_SETTINGS)
    ) :
    toast.error(GENERIC_ERROR, TOAST_SETTINGS);