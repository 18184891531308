export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const IDE_APPLICATION = "/ide/application/:id";
export const DASHBOARD = "/dashboard";
export const DASHBOARD_TAB = "/dashboard/:tab";
export const APPLICATION = "/application";
export const APPLICATIONS = "/dashboard/applications";
export const APPLICATION_EDIT = "/application/:id";
export const ACCOUNT_ROUTE = "/account";
export const COMPONENT_ROUTE = "/component/:id";
export const WIZARD = "/ide/application/:applicationId/run-wizard";
