import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const RadioButtonSelect = (props) =>
{
    const [option, setOption] = useState(props.defaultValue?.value || "");

    const handleChange = (event) =>
    {
        setOption(event.target.value);
        const chosenInput = props.configuration.options.find(
            option => option.value === event.target.value
        );
        props.handleConfigurationChange(chosenInput, props.configuration);
    };

    return (
        <FormControl>
            <RadioGroup value={ option } onChange={ handleChange }>
                {
                    props.configuration.options.map(option =>
                        <FormControlLabel
                            labelPlacement="start"
                            key={ option.value }
                            value={ option.value }
                            control={ <Radio /> }
                            label={ option.title }
                        />
                    )
                }
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonSelect;
