import { FULL, THUMBNAIL } from "../constants/imageStyles";

export const getImageUri = (image, style) =>
    image && image.imageFiles && image.imageFiles.length > 0 ?
        "/" + image.imageFiles
            .find(file => file.imageStyle && style === file.imageStyle)?.uri :
        null;

export const getThumbnailUri = (image) => getImageUri(image, THUMBNAIL);

export const getFullUri = (image) => getImageUri(image, FULL);
