export class UnknownError extends Error
{
    content;

    constructor(content)
    {
        super();
        this.content = content;
    }
}
