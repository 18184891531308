import React, { useContext, useEffect, useState } from "react";
import { CURRENT_USER } from "../../_common/context";
import "../style/home.sass";
import Account from "../../user/components/Account";
import Modal from "@mui/material/Modal";
import {
    getUser,
    updateUser,
    updateUserStatus
} from "../../user/service/userApiCalls";
import { useNavigate } from "react-router-dom";
import { DASHBOARD, LOGIN_ROUTE } from "../../_common/constants/routes";
import { catchUnauthorized } from "../../_common/helper/functions";
import OwnedItemsDashboard from "./OwnedItemsDashboard";
import ClaimAndStake from "./ClaimAndStake";

const Home = () =>
{
    const { user, setUser } = useContext(CURRENT_USER);
    const navigate = useNavigate();
    const [isNewUser, setIsNewUser] = useState(user.newUser);
    const [newUserModalOpen, setNewUserModalOpen] = useState(true);
    !user.username && navigate(LOGIN_ROUTE);

    useEffect(
        () => {
            user.username && setIsNewUser(user.newUser);
        },
        [user]
    );

    const handleSkip = () =>
    {
        const dto = {
            email: user.email,
            name: user.name,
            newUser: false,
            declaredPackages: user.declaredPackages
                .map(declaredPackage => declaredPackage.packageName)
        };

        updateUser(dto)
            .then(() => {
                navigate(DASHBOARD);
                getUser()
                    .then(currentUser =>
                        updateUserStatus(user, currentUser, setUser)
                    )
                    .catch((e) => catchUnauthorized(e, setUser));
            })
            .catch((e) => catchUnauthorized(e, setUser));
    };

    const newUserModal = () => (
        <Modal
            open={ newUserModalOpen }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modal-content">
                <Account
                    modal={
                        { open: newUserModalOpen, setOpen: setNewUserModalOpen }
                    }
                >
                    <button className="btn skip-btn" onClick={ handleSkip }>
                        Skip step
                    </button>
                </Account>
            </div>
        </Modal>
    );

    return (
        <div className="home-container">
            { isNewUser && newUserModal() }
            <div className="heading">
                <h2>Fees & Rewards</h2>
                <div>
                    Here you can see a breakdown of the fees & rewards from your
                    components and applications, based on the number of usages
                    by other IBL users.
                </div>
            </div>
            <div className="row">
                <div className="content-left col-8">
                    <OwnedItemsDashboard />
                </div>
                <div className="content-right col">
                    <ClaimAndStake />
                </div>
            </div>
        </div>
    );
};

export default Home;
