import React, { useState, useEffect } from "react";
import { GridDropZone, GridItem } from "react-grid-dnd";

const IdeComponentsList = (props) =>
{
    const [mouseDown, setMouseDown] = useState(false);

    useEffect(() => {
        window.addEventListener("mouseup", () => 
            window.removeEventListener("mousemove", setMouseDown(false))
        );
    });

    return (
        <div className=
            { `col-md-2 component-menu ${ !mouseDown ? "scroll" : "" }` }>
            <input
                type="text"
                className="form-control"
                placeholder="Search components"
                onChange={ e => props.setFilter(e.target.value) }
            />
            <p className="description">
                Select, drag & drop on the drawing board the component you want
            </p>
            <GridDropZone
                className="available-components"
                id="availableComponents"
                boxesPerRow={1}
                rowHeight={70}
            >
                {
                    props.dropZones.availableComponents &&
                    props.dropZones.availableComponents.map(component => (
                        <div
                            className="gridItem"
                            key={component.id}
                            onMouseDown={ () => setMouseDown(true) }
                            onMouseUp={ () => setMouseDown(false) }
                        >
                            <ListGridItem component={component}/>
                        </div>
                    ))
                }
            </GridDropZone>
        </div>
    );
};

const ListGridItem = (props) => (
    <GridItem
        key={ props.component.id }
        className="component"
        id={ props.component.id }
        data-toggle="tooltip"
        title={ props.component.displayName }
    >
        { props.component.displayName }
    </GridItem>
);

export default IdeComponentsList;
