import { configurations } from "../../_common/configurations";
import {
    checkApiResponse,
    get,
    patch,
    post,
    del
} from "../../_common/services/webClient";

const APPLICATION_API = configurations.apiUrl + "/applications";
const APPLICATION_CONFIGURATIONS_API =
    configurations.apiUrl + "/application-configurations";
const APPLICATION_DEPLOYMENTS_API =
    configurations.apiUrl + "/application-deployments";

export const fetchApplications = () => get(APPLICATION_API)
    .then(checkApiResponse)
    .then(response => response.json());

export const fetchUsedApplications = () => get(`${APPLICATION_API}/used`)
    .then(checkApiResponse)
    .then(response => response.json());

export const fetchLatestApplicationVersion = (id) =>
    get(`${APPLICATION_API}/${id}/latest`).then(response => response.json());

export const fetchApplicationVersion = (id) =>
    get(`${APPLICATION_API}/${id}`).then(response => response.json());

export const createApplication = (payload) => post(APPLICATION_API, payload)
    .then(checkApiResponse);

export const createDraft = (payload) =>
    post(`${APPLICATION_API}/draft`, payload).then(checkApiResponse);

export const updateApplication = (id, payload) =>
    patch(`${APPLICATION_API}/${id}`, payload).then(checkApiResponse);

export const saveDraft = (id, payload) =>
    patch(`${APPLICATION_API}/${id}/draft`, payload).then(checkApiResponse);

export const deleteApplication = (id) => del(`${APPLICATION_API}/${id}`)
    .then(checkApiResponse);

export const downloadApplication = (id) =>
    get(`${APPLICATION_CONFIGURATIONS_API}/${id}/download`)
        .then(checkApiResponse);

export const deployApplication = (id) =>
    post(`${APPLICATION_DEPLOYMENTS_API}/${id}/deploy`, {})
        .then(checkApiResponse);

export const deployPhase = (id) =>
    get(`${APPLICATION_DEPLOYMENTS_API}/latest/${id}`)
        .then(response => response.json());