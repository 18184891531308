import React, { useContext, useEffect, useState } from "react";
import OwnedItemList from "./OwnedItemList";
import { CURRENT_USER } from "../../_common/context";
import {
    fetchComponents,
    getNumberOfUsages
} from "../../component/clients/componentClient";
import { catchUnauthorized } from "../../_common/helper/functions";
import {
    fetchUsedApplications
} from "../../application/clients/applicationClient";
import feLibrary from "../../_common/services/FrontEndLibrary";
import { BigNumber, ethers } from "ethers";
import Claim from "./Claim";

const OwnedItemsDashboard = () =>
{
    const { user, setUser } = useContext(CURRENT_USER);
    const [components, setComponents] = useState([]);
    const [applications, setApplications] = useState([]);
    const [appFees, setAppFees] = useState(BigNumber.from("0"));
    const [componentFees, setComponentFees] = useState(BigNumber.from("0"));
    const [stateChanged, setStateChanged] = useState(false);

    useEffect(
        () => {
            setStateChanged(false);
            if (user.username) {
                getComponentData();
                getApplicationData();
                feLibrary.getAccumulatedApplicationsFee(user.username)
                    .then(setAppFees);
                feLibrary.getAccumulatedComponentsFee(user.username)
                    .then(setComponentFees);
            }
        },
        [user.username, stateChanged]
    );

    const getComponentData = () => fetchComponents().then(componentList => {
        const components = [];
        const componentPromises = componentList.map((component) =>
            getNumberOfUsages(component.componentId)
                .then((response) => components.push({
                    id: component.id,
                    name: component.name,
                    data: {
                        "your quota": getUserQuota(component.owners),
                        usages: response.usages
                    }
                }))
        );

        Promise.all(componentPromises).then(() => setComponents(components))
            .catch(error => {
                setComponents([]);
                catchUnauthorized(error, setUser);
            });
    });

    const getApplicationData = () => fetchUsedApplications().then(appList => {
        const applications = [];
        const appPromises = appList.map((app) =>
            feLibrary.getApplicationFee(app.applicationId)
                .then((fees) => applications.push({
                    id: app.id,
                    name: app.name,
                    data: { fees: ethers.utils.formatEther(fees) + " ETH" }
                }))
        );

        Promise.all(appPromises).then(() => setApplications(applications))
            .catch(error => {
                setApplications([]);
                catchUnauthorized(error, setUser);
            });
    });

    const getUserQuota = (owners) =>
        owners.find((owner) => owner.walletAddress === user.username).quota;

    return (
        <div className="owned-items section padded">
            <section className="row">
                <div className="col">
                    <div className="section-title">Your components</div>
                    {
                        components.length > 0 ?
                            <div className="item-list">
                                <OwnedItemList items={components}/>
                            </div> :
                            <div className="item">No items in the list</div>
                    }
                </div>
                <div className="col">
                    <div className="section-title">Your applications</div>
                    {
                        applications.length > 0 ?
                            <div className="item-list">
                                <OwnedItemList items={applications}/>
                            </div> :
                            <div className="item">No items in the list</div>
                    }
                </div>
            </section>
            <section className="claims row">
                <div className="col claim-container">
                    <Claim
                        title={ "Total fees from your components" }
                        value={ componentFees }
                        currency={ "ETH" }
                        claim={
                            () => feLibrary.claimComponentFees(user.username)
                        }
                        resetState={ () => setStateChanged(true) }
                        buttonText={ "Claim fees" }
                        iconName={ "coins" }
                    />
                </div>
                <div className="col claim-container">
                    <Claim
                        title={ "Total fees from your applications" }
                        value={ appFees }
                        currency={ "ETH" }
                        claim={ () => feLibrary.claimAppFees(user.username) }
                        resetState={ () => setStateChanged(true) }
                        buttonText={ "Claim fees" }
                        iconName={ "coins" }
                    />
                </div>
            </section>
        </div>
    );
};

export default OwnedItemsDashboard;