import React, { useContext, useEffect, useState } from "react";
import { ERROR, NO_CLIENT } from "../constants/userStatus";
import { CURRENT_USER } from "../../_common/context";
import { HOME_ROUTE } from "../../_common/constants/routes";
import { useNavigate } from "react-router-dom";
import {
    connectWallet,
    switchToDefaultNetwork
} from "../service/clients/metamaskClient";
import { NoClientError } from "../errors/NoClientError";
import { getUser, updateUserStatus } from "../service/userApiCalls";
import { configurations } from "../../_common/configurations";
import fundsLogos from "../../_common/assets/images/funds-logos.png";

const METAMASK_DOWNLOAD_LINK = `https://metamask.io/download.html`;
const METAMASK_LINK_TEXT = "You must install Metamask, a virtual Ethereum " +
    "wallet, in your browser.";

const LoginPage = () =>
{
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const { user, setUser } = useContext(CURRENT_USER);

    useEffect(
        () => window.ethereum && switchToDefaultNetwork,
        []
    );

    useEffect(
        () => { user.username && navigate(HOME_ROUTE); },
        [user.username]
    );

    const connect = () => connectWallet()
        .then(walletResponse => getUser().then(
            user => updateUserStatus(walletResponse, user, setUser)
        ))
        .catch(error => {
            setErrorMessage(
                "Something went wrong and you are not able to connect to " +
                "your account. Please try again."
            );
            setUser(
                error instanceof NoClientError ?
                    { username: "", status: NO_CLIENT } :
                    { username: "", status: ERROR }
            );
        });

    return (
        <div className="connect-container">
            <h1>Hi!</h1>
            <p>To access IBL you need to connect your MetaMask wallet.</p>
            <img src={ require("../../_common/assets/images/metamask-bw.png") }
                alt="metamask-icon" />
            {
                errorMessage &&
                <div className="error mb-3">{ errorMessage }</div>
            }
            {
                window.ethereum && 
                window.ethereum.chainId !== configurations.defaultChainId ?
                    <div>Unsupported Network</div>  :
                    <button className="btn button login-btn"
                        onClick={ connect }>
                        Connect wallet
                    </button>
            }
            {
                user.status === NO_CLIENT &&
                    <div>
                        <a target="_blank" rel="noreferrer"
                            href={ METAMASK_DOWNLOAD_LINK }>
                            { METAMASK_LINK_TEXT }
                        </a>
                    </div>
            }

            <div className="funds-logos-container">
                <img src={ fundsLogos } alt="funds-logos" />
                <p>
                    Proiectul IBL – dezvoltarea unei soluţii inovative şi 
                    accesibile de automatizare, cod SMIS 129898 este cofinanţat 
                    din FEDR prin Program Operațional Competitivitate 
                    2014 – 2020 
                </p>
            </div>
        </div>
    );
};

export default LoginPage;
