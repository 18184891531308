import React from "react";

const Disconnect = () =>
{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.001" height="20"
            viewBox="0 0 20.001 20">
            <path id="Subtraction_3" data-name="Subtraction 3"
            // eslint-disable-next-line
                d="M-8506,290h-20V270h20v20Zm-12.223-4.442v2.223h11.116V272.218h-11.116v2.223h8.893v11.116Zm-2.226-8.893h0l-4.446,
                    3.336,4.446,3.334v-2.223h8.9v-2.223h-8.9v-2.223Z"
                transform="translate(8526.001 -269.999)"/>
        </svg>
    );
};

export default Disconnect;