export const MANDATORY_ERROR = "This field is mandatory";
export const GENERIC_ERROR = "Something went wrong and we are not able to " +
    "save the changes. Please try again";
export const GENERIC_INPUT_ERROR = "Something went wrong. " +
    "Please check your inputs.";
export const REJECTED_PAYMENT = "Failed to register payment";
export const UNKNOWN_ERROR = "";
export const COMPONENTS_NOT_FOUND = "Some components are no longer available " +
    "and were removed from your application along with their connections. ";
export const UPDATE_APPLICATION = "Please update the created application.";
export const REVIEW_CONFIGURATIONS =
    "Please review the configurations and input mappings!";
export const UPDATED_COMPONENTS =
    "The following components were updated with new versions:";
export const THEME_NOT_FOUND =
    "Theme not found. Please select one from the dropdown!";
export const MANDATORY_URL = "In order to publish this application, please go" +
    " back to the previous step and choose a subdomain name for it";
export const DOMAIN_IS_ALREADY_TAKEN = "Please choose another domain for " +
    "your application. The given domain is already taken";
export const INVALID_DOMAIN = "Please only use lower case letters, numbers " +
    "or dashes. Also the subdomain must begin with a letter.";
export const CLAIM_ERROR = "An error occurred, please try again!";