import React from "react";
import { INPUT_MAXLENGTH } from "../constants/maxInputLength";
import { displayError } from "../../_common/helper/functions";

const InputOutputTab = ({
    component,
    onInputOutputChange,
    errorsList,
}) => {
    const displayInputOrOutput = (ioObject, type) => (
        <div className="mb-3" key={ ioObject.machineName }>
            <label className="form-label">{ ioObject.machineName }</label>
            <input
                className={
                    "form-control " + getClassName(type, ioObject.machineName)
                }
                defaultValue={ ioObject.title || "" }
                placeholder={ "Rename " + ioObject.machineName }
                maxLength={ INPUT_MAXLENGTH }
                onChange={ (e) => onInputOutputChange(e, ioObject, type) }
            />
            {
                ioObject.machineName in errorsList[type] &&
                errorsList[type][ioObject.machineName].map(displayError)
            }
            <small>max { INPUT_MAXLENGTH } characters</small>
        </div>
    );

    const getClassName = (type, machineName) =>
        errorsList[type][machineName]?.length > 0 ? "not-valid" : "";

    return (
        <div className="row">
            <div className="col-md-6 component-details">
                <div className="mb-3" key="input">
                    <h3>Input</h3>
                    {
                        component &&
                        component.input.map((input) =>
                            displayInputOrOutput(input, "input")
                        )
                    }
                </div>
            </div>
            <div className="col-md-6">
                <div className="mb-3" key="output">
                    <h3>Output</h3>
                    {
                        component &&
                        component.output.map((output) =>
                            displayInputOrOutput(output, "output")
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default InputOutputTab;
