export const NOT_CONFIGURED = {
    message: "This component is not yet configured.",
    className: "incomplete"
};
export const NEEDS_CONNECTIONS = {
    message: "This component needs connections.",
    className: "error"
};
export const COMPONENT_UPDATED = {
    message: "This component was updated." +
        "Please review the configurations and input mappings",
    className: "error"
};
export const COMPONENT_OK = { message: "Looking good!", className: "" };
