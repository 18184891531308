import { BigNumber, ethers } from "ethers";
import "../style/ide.sass";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CURRENT_USER } from "../../_common/context";
import { useNavigate, useParams } from "react-router-dom";
import { DASHBOARD, LOGIN_ROUTE } from "../../_common/constants/routes";
import { GridContextProvider, move } from "react-grid-dnd";
import {
    getLatestComponents,
    getLatestVersion,
} from "../clients/componentClient";
import { getLatestById } from "../../component/clients/componentClient";
import { v4 as uuid } from "uuid";
import {
    deployApplication,
    downloadApplication,
    fetchApplicationVersion,
    saveDraft,
    updateApplication,
    fetchApplications
} from "../../application/clients/applicationClient";
import Graphics from "./IdeGraphics";
import IdeComponentsList from "./IdeComponentsList";
import IdeControls, { setGridDimensions } from "./IdeControls";
import DrawingBoardDropZones from "./DrawingBoardDropZones";
import ConfirmModal from "../../_common/notifications/ConfirmModal";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../_common/constants/messageStyles";
import CustomizeComponentSidebar from "./CustomizeComponentSidebar";
import { NEEDS_CONNECTIONS } from "../common/ideComponentInfo";
import {
    catchUnauthorized,
    processDownload
} from "../../_common/helper/functions";
import { FEE_GROUPS } from "../../_common/constants/feeGroups";
import { BadRequest } from "../../user/errors/BadRequest";
import {
    COMPONENTS_NOT_FOUND,
    GENERIC_ERROR,
    REJECTED_PAYMENT,
    REVIEW_CONFIGURATIONS,
    UPDATE_APPLICATION,
    UPDATED_COMPONENTS
} from "../../_common/constants/errors";
import { buildComponentErrorMessage } from "../common/helperFunctions";
import {
    APPLICATION_SAVED, SAVED_AS_DRAFT, PAYMENT_PROCESSING
} from "../../_common/constants/notifications";
import IdeMenu from "./IdeMenu";
import CustomizeConnectionSidebar from "./CustomizeConnectionSidebar";
import { UnknownError } from "../../user/errors/UnknownError";
import {
    isDefaultNetwork,
    payInfrastructureFee,
    switchToDefaultNetwork,
} from "../../user/service/clients/metamaskClient";
import feLibrary from "../../_common/services/FrontEndLibrary";
import { registerPayment } from "../../_common/payment/paymentClient";
import {
    createSubscription,
    getActiveSubscriptions
} from "../clients/subscriptionsClient";
import { configurations } from "../../_common/configurations";
import { handleError } from "../common/errorHandling";
import _ from "lodash";

const SUBSCRIPTION_POLL_COUNT = 10;

const Ide = () =>
{
    const { id } = useParams();
    const navigate = useNavigate();
    const { user, setUser } = useContext(CURRENT_USER);
    !user.username && navigate(LOGIN_ROUTE);
    const [filter, setFilter] = useState("");
    const [latestComponents, setLatestComponents] = useState([]);
    const [onBoardComponents, setOnBoardComponents] = useState({});
    const [dropZones, setDropZones] = useState({});
    const [application, setApplication] = useState({});
    const [configuredComponent, setConfiguredComponent] = useState(null);
    const [selectedConnection, setSelectedConnection] = useState(null);
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const [grid, setGrid] = useState({ rows: [-1, 0, 1], cols: [-1, 0, 1] });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showModal, setShowModal] = useState(false);
    const [modalDetails, setModalDetails] = useState({});
    const [isApplicationComplete, setApplicationComplete] = useState(false);
    const [formHasChanges, setFormHasChanges] = useState(false);
    const [formIsInitialized, setFormIsInitialized] = useState(false);
    const [unpaidComponents, setUnpaidComponents] = useState({});
    const [extraFees, setExtraFees] = useState({});
    const [downloadedComponents, setDownloadedComponents] = useState();
    const [initialAppFee, setInitialAppFee] = useState(BigNumber.from("0"));
    const [changedAppFee, setChangedAppFee] = useState("0.0");

    const componentRef = useRef(null);
    const connectionRef = useRef(null);

    const NO_OWNER_ADDRESS = "0x0000000000000000000000000000000000000000";

    useEffect(
        () => {
            switchToDefaultNetwork();
            fetchApplicationVersion(id)
                .then(app => {
                    setApplication(app);
                    app.status !== "DRAFT" && setApplicationComplete(true);
                })
                .catch((error) => catchUnauthorized(error, setUser));
        },
        []
    );
    useEffect(
        () => {
            if (Object.keys(application).length !== 0) {
                populateDownloadedComponents(application);
                feLibrary.getAppFee(application.applicationId)
                    .then((appFee) => {
                        setInitialAppFee(ethers.utils.formatEther(appFee));
                        setChangedAppFee(ethers.utils.formatEther(appFee));
                    });
            }
        },
        [application]
    );
    useEffect(
        () => {
            if (downloadedComponents) {
                const firstProcessComponentsLength =
                    application.processes[0]?.configuredComponents.length;
                const initialDropZones = {};
                getLatestComponents()
                    .then(latestComponents => {
                        setLatestComponents(latestComponents);
                        let errorComponentIds = [];
                        application.errors &&
                            Object.keys(application.errors).forEach(key => {
                                const [process, component] =
                                    key.match(/(\d+)/g);
                                errorComponentIds.push(
                                    application.processes[process]
                                        .configuredComponents[component]
                                        .componentId
                                );
                            });
                        if (firstProcessComponentsLength > 0) {
                            buildDrawingBoardData(
                                application, latestComponents,
                                initialDropZones, errorComponentIds
                            );
                        } else {
                            setFormIsInitialized(true);
                        }
                    })
                    .catch((error) => catchUnauthorized(error, setUser));
            }
        },
        [downloadedComponents]
    );
    useEffect(
        () => {
            document.addEventListener("mousedown", handleClickOutside, true);

            return () => document
                .removeEventListener("mousedown", handleClickOutside, true);
        },
        [sidebarOpened]
    );
    useEffect(
        () => {
            updateDropZones(calculateDropZones(), latestComponents);
        },
        [grid]
    );
    useEffect(
        () => {
            const currentDropZones = Object.keys(dropZones).length !== 0 ?
                dropZones : calculateDropZones();
            if (latestComponents.length > 0) {
                updateDropZones(
                    currentDropZones, filterComponents(latestComponents)
                );
            } else {
                getLatestComponents().then(componentList => updateDropZones(
                    currentDropZones, filterComponents(componentList)
                ));
            }
        },
        [filter, latestComponents]
    );
    useEffect(
        () => {
            window.addEventListener("resize", handleResize);

            return () => window.removeEventListener("resize", handleResize);
        },
        [windowWidth]
    );
    useEffect(
        () => {
            formIsInitialized && setFormHasChanges(true);
        },
        [onBoardComponents]
    );
    useEffect(
        () => {
            const timer = setTimeout(
                () => {
                    setExtraFees({
                        hosting : ethers.utils.parseUnits(
                            configurations.infrastructureFee, "ether"
                        ),
                        protocol: getComponentsTotal("downloadPrice"),
                        gas: null,
                    });
                },
                1000
            );

            return () => clearTimeout(timer);
        },
        [unpaidComponents]
    );

    const populateDownloadedComponents = (app) => fetchApplications()
        .then((applicationList) =>
            applicationList.find(application =>
                application.applicationId === app.applicationId &&
                application.status === "DOWNLOADED"
            ))
        .then((matchingApp) =>
            matchingApp && matchingApp.processes[0].configuredComponents.map(
                (configuredComponent) => getLatestById(
                    configuredComponent.componentId
                ).then((component) => component.componentId)
            ))
        .then((downloadedIds) => downloadedIds ?
            Promise.all(downloadedIds).then((downloadedIds) =>
                setDownloadedComponents(downloadedIds)
            ) :
            setDownloadedComponents([])
        );

    const calculateFees = (onBoardComponents) =>
    {
        const newUnpaidComponents = { ...unpaidComponents };
        onBoardComponents
            .filter((onBoardComponent) =>
                !downloadedComponents.includes(
                    onBoardComponent.component.componentId
                ) &&
                !(onBoardComponent.component.componentId in unpaidComponents)
            )
            .forEach((onBoardComponent) => {
                const componentId = onBoardComponent.component.componentId;
                const name = onBoardComponent.component.displayName;
                feLibrary.getComponent(componentId).then((componentFees) => {
                    newUnpaidComponents[componentId] =
                        { ...componentFees, displayName: name };
                });
            });
        setUnpaidComponents(newUnpaidComponents);
    };

    const removeFee = (removedComponent, nextOnBoardComponents) =>
    {
        let noDuplicateOnBoard = Object.values(nextOnBoardComponents)
            .every((onBoardComponent) =>
                onBoardComponent.component.componentId !==
                    removedComponent.componentId
            );
        noDuplicateOnBoard && setUnpaidComponents(prevState => {
            const newUnpaidComponents = { ...prevState };
            delete newUnpaidComponents[removedComponent.componentId];

            return newUnpaidComponents;
        });
    };

    const calculateDropZones = () =>
    {
        const currentDropZones = {};
        Object.values(onBoardComponents).forEach(onBoardComponent =>
            currentDropZones[onBoardComponent.dropZoneId] =
                latestComponents.filter(component =>
                    component.id === onBoardComponent.component.id
                )
        );
        grid.rows.forEach(row => grid.cols.forEach(col => {
            const id = row + "_" + col;
            if (!(id in currentDropZones)) {
                currentDropZones[id] = dropZones[id] || [];
            }
        }));

        return currentDropZones;
    };

    const handleResize = () => setWindowWidth(window.innerWidth);

    const handleClickOutside = (e) =>
        (
            componentRef.current && !componentRef.current.contains(e.target) ||
            connectionRef.current && !connectionRef.current.contains(e.target)
        ) && updateAndResetSidebar();

    const buildDrawingBoardData = (
        app, latestComponents, initialDropZones, errorComponentIds
    ) =>
    {
        const newOnBoardComponents = {};
        const updatedComponentList = [];
        const connections = [];
        const configuredComponents = app.processes[0].configuredComponents;

        const promises = configuredComponents.map(configuredComponent => {
            const componentId = configuredComponent.componentId;
            const nextComponentSelector =
                configuredComponent.nextComponentSelectorDto ?? null;
            nextComponentSelector?.type === "DIRECT" && connections.push({
                buttonName: nextComponentSelector.connectionDto.name,
                start: {
                    gridItem: configuredComponent.instanceId,
                    node: nextComponentSelector.connectionDto.startPoint,
                },
                end: {
                    gridItem: nextComponentSelector.nextConfiguredComponentId,
                    node: nextComponentSelector.connectionDto.endPoint,
                }
            });

            const matchingComponent = latestComponents
                .find(component => componentId === component.id);

            const foundMatchingComponent = matchingComponent ?
                Promise.resolve(matchingComponent) :
                getLatestVersion(componentId);

            return foundMatchingComponent.then(latest => {
                newOnBoardComponents[configuredComponent.instanceId] = {
                    component: latest,
                    configData: {
                        configurations: configuredComponent.configurations,
                        inputMappings:
                            setInputMappings(latest, configuredComponent),
                    },
                    dropZoneId: configuredComponent.drawingBoardPosition,
                    instanceId: configuredComponent.instanceId,
                    hasConfigurationsCompleted:
                        !!matchingComponent &&
                        !errorComponentIds.includes(latest.id),
                    hasInputMappingsCompleted: inputMappingsComplete(
                        latest.input, configuredComponent.inputMappings
                    ),
                    isUpdated: !matchingComponent,
                    incomingConnections: [],
                    outgoingConnections: []
                };
                initialDropZones[configuredComponent.drawingBoardPosition] =
                    [latest];
                !matchingComponent && updatedComponentList.push(latest);
            }).catch(errorResponse => {
                if (
                    errorResponse instanceof BadRequest ||
                    errorResponse instanceof UnknownError
                ) {
                    toast.error(
                        COMPONENTS_NOT_FOUND + UPDATE_APPLICATION,
                        TOAST_SETTINGS
                    );
                }
            });
        });

        return Promise.all(promises).then(() => {
            updatedComponentList.length > 0 && toast.error(
                buildComponentErrorMessage(
                    UPDATED_COMPONENTS,
                    updatedComponentList,
                    REVIEW_CONFIGURATIONS
                ),
                TOAST_SETTINGS
            );
            connections.forEach(connection => {
                const start = newOnBoardComponents[connection.start.gridItem];
                const end = newOnBoardComponents[connection.end.gridItem];
                if (start && end) {
                    start.outgoingConnections.push(connection);
                    end.incomingConnections.push(connection);
                }
            });
            setOnBoardComponents(newOnBoardComponents);
            calculateFees(Object.values(newOnBoardComponents));
            Object.keys(newOnBoardComponents).length > 0 &&
            setGridDimensions(newOnBoardComponents, grid, setGrid);
            populateDropZones(initialDropZones);
            updateDropZones(initialDropZones, latestComponents);

            setTimeout(() => setFormIsInitialized(true));
        });
    };

    const setInputMappings = (latestComponent, configuredComponent) =>
    {
        const inputs = latestComponent.input.map((input) => input.machineName);

        return configuredComponent.inputMappings
            .filter((inputMapping) => inputs.includes(inputMapping.inputName))
            .reduce(
                (o, mapping) => ({ ...o, [mapping.inputName]: mapping }),
                {}
            ) ||
            {};
    };

    const inputMappingsComplete = (requiredInputs, configuredInputs) =>
    {
        const inputs = configuredInputs.map((input) => input.inputName);

        return requiredInputs.every((input) =>
            inputs.includes(input.machineName)
        );
    };

    const updateDropZones = (currentDropZones, componentList) => setDropZones({
        ...currentDropZones, availableComponents: componentList
    });

    const populateDropZones = (initialDropZones) =>
        grid.rows.forEach(row => grid.cols.forEach(col => {
            const id = row + "_" + col;
            if (!(id in initialDropZones)) {
                initialDropZones[id] = dropZones[id] || [];
            }
        }));

    const filterComponents = (componentList) => componentList.filter(
        component => component.displayName.toLowerCase()
            .includes(filter.toLowerCase())
    );

    const onDragAndDropComponents = (
        sourceId, sourceIndex, targetIndex, targetId
    ) => {
        if (!targetId) {
            return;
        }

        const result = move(
            dropZones[sourceId], dropZones[targetId], sourceIndex, targetIndex
        );
        const destinationIsEmpty = dropZones[targetId].length === 0;

        if (targetId === "availableComponents") {
            setupModal(
                () => removeComponent(sourceId),
                <>
                    <p className="modal-title">
                        Are you sure you want to delete the component?
                    </p>
                    <p className="modal-description">
                        You will lose all the configurations for the
                        deleted component
                    </p>
                </>
            );
        } else if (destinationIsEmpty && sourceId === "availableComponents") {
            const component = dropZones[sourceId][sourceIndex];
            const instanceId = uuid();
            const onBoardComponent = {
                instanceId: instanceId,
                component: component,
                dropZoneId: targetId,
                incomingConnections: [],
                outgoingConnections: [],
                hasConfigurationsCompleted: false,
                hasInputMappingsCompleted: component.input.length === 0,
                configData: {}
            };

            if (onBoardComponents && component.input.length !== 0) {
                toast.info(NEEDS_CONNECTIONS.message, TOAST_SETTINGS);
            }
            setOnBoardComponents({
                ...onBoardComponents, [instanceId]: onBoardComponent
            });
            calculateFees([onBoardComponent]);
            customizeComponent(onBoardComponent);
            setDropZones({ ...dropZones, [targetId]: result[1] });
        } else if (destinationIsEmpty && sourceId !== "availableComponents") {
            const movedComponentId = Object.values(onBoardComponents).find(
                component => component.dropZoneId === sourceId
            )?.instanceId;
            if (movedComponentId !== undefined) {
                const component = { ...onBoardComponents[movedComponentId] };
                component["dropZoneId"] = targetId;

                setOnBoardComponents({
                    ...onBoardComponents, [movedComponentId]: component
                });
                setDropZones({
                    ...dropZones, [sourceId]: result[0], [targetId]: result[1]
                });
            }
        }
    };

    const removeComponent = (sourceId) =>
    {
        const removedComponent = Object.values(onBoardComponents)
            .find(component => component.dropZoneId === sourceId);

        removedComponent.incomingConnections.forEach(deleteConnection);
        removedComponent.outgoingConnections.forEach(deleteConnection);

        const nextOnBoardComponents = { ...onBoardComponents };
        delete nextOnBoardComponents[removedComponent.instanceId];

        setOnBoardComponents(nextOnBoardComponents);
        removeFee(removedComponent.component, nextOnBoardComponents);
        setDropZones({ ...dropZones, [sourceId]: [] });
    };

    const deleteConnection = (connection) =>
    {
        const newOnBoardComponents = { ...onBoardComponents };
        const startComponent = newOnBoardComponents[connection.start.gridItem];
        const endComponent = newOnBoardComponents[connection.end.gridItem];

        getDownstreamComponentIds(startComponent).forEach((id) => {
            const component = newOnBoardComponents[id];
            const inputMappings = component.configData.inputMappings;
            inputMappings && Object.values(inputMappings).forEach((mapping) =>
                Object.keys(getUpstreamComponents(endComponent))
                    .includes(mapping.sourceComponentId) &&
                delete component.configData.inputMappings[mapping.inputName]
            );
            component.hasInputMappingsCompleted = inputMappingsComplete(
                component.component.input,
                Object.keys(inputMappings || {})
            );
            inputMappings && Object.keys(inputMappings).length === 0 &&
                delete component.configData.inputMappings;
        });

        const connectionJson = JSON.stringify(connection);
        startComponent.outgoingConnections = startComponent.outgoingConnections
            .filter(item => JSON.stringify(item) !== connectionJson);
        endComponent.incomingConnections = endComponent.incomingConnections
            .filter(item => JSON.stringify(item) !== connectionJson);

        setOnBoardComponents(newOnBoardComponents);
    };

    const getDownstreamComponentIds = (startComponent) =>
    {
        const linkedComponents = [];
        while (startComponent.outgoingConnections.length > 0) {
            const nextComponentId =
                startComponent.outgoingConnections[0].end.gridItem;
            startComponent = onBoardComponents[nextComponentId];
            linkedComponents.push(nextComponentId);
        }

        return linkedComponents;
    };

    const getUpstreamComponents = (configuredComponent) =>
    {
        const linkedComponents = {};
        while (configuredComponent.incomingConnections.length > 0) {
            const previousComponentId =
                configuredComponent.incomingConnections[0].start.gridItem;
            configuredComponent = onBoardComponents[previousComponentId];
            linkedComponents[previousComponentId] =
                configuredComponent.component;
        }

        return linkedComponents;
    };

    const getUpstreamOutputs = (configuredComponent) =>
        Object.entries(getUpstreamComponents(configuredComponent)).reduce(
            (result, [instanceId, configuredComponent]) => {
                result[instanceId] = configuredComponent.output?.reduce(
                    (o, output) => ({
                        ...o,
                        [instanceId + "|" + output.machineName]: output
                    }),
                    {}
                );

                return result;
            },
            {}
        );

    const customizeConnection = (connection) =>
    {
        sidebarOpened && updateAndResetSidebar();
        setSelectedConnection(connection);
        setSidebarOpened(true);
    };

    const customizeComponent = (configuredComponent) =>
    {
        sidebarOpened && updateAndResetSidebar();
        setSidebarOpened(true);
        setConfiguredComponent(configuredComponent);
    };

    const setupModal = (action, message) =>
    {
        setShowModal(true);
        setModalDetails({ message: message, action: action });
    };

    const updateOnBoardComponents = (onBoardComponentList) =>
    {
        const nextOnBoardComponents = { ...onBoardComponents };
        onBoardComponentList.forEach(onBoardComponent =>
            nextOnBoardComponents[onBoardComponent.instanceId] =
                onBoardComponent
        );
        setOnBoardComponents(nextOnBoardComponents);
    };

    const updateAndResetSidebar = (data = null) =>
    {
        if (data?.componentsToUpdate) {
            updateOnBoardComponents(data.componentsToUpdate);
        } else if (configuredComponent) {
            const onBoardComponent = { ...configuredComponent };
            onBoardComponent.configData.configurations =
                onBoardComponent.configData.configurations || {};
            updateOnBoardComponents([onBoardComponent]);
        } else if (selectedConnection) {
            const onBoardComponent =
                { ...onBoardComponents[selectedConnection.end.gridItem] };
            onBoardComponent.configData.inputMappings =
                onBoardComponent.configData?.inputMappings || {};
            updateOnBoardComponents([onBoardComponent]);
        }
        setSidebarOpened(false);
        setConfiguredComponent(null);
        setSelectedConnection(null);
    };

    const saveApplication = (clientMethod) =>
    {
        const configuredComponentList = [];
        componentsWithoutIncomingConnections().forEach(id =>
            configuredComponentList.push(...getConnectedComponents(id))
        );

        const dto = {
            name: application.name,
            deploymentType: application.deploymentType,
            domain: application.domain,
            metaTitle: application.metaTitle,
            metaDescription: application.metaDescription,
            logoId: application.logoId,
            themeId: application.themeId,
            processes: [
                {
                    menuTitle: application.name,
                    uri: application.name.toLowerCase().replace(" ", "-"),
                    configuredComponents: configuredComponentList
                }
            ]
        };

        return clientMethod(application.applicationId, dto).then((response) => {
            clientMethod === updateApplication ?
                toast.success(APPLICATION_SAVED, TOAST_SETTINGS):
                toast.info(SAVED_AS_DRAFT, TOAST_SETTINGS);

            return response;
        });
    };

    const componentsWithoutIncomingConnections = () =>
        Object.values(onBoardComponents).reduce(
            (acc, component) => {
                !component.incomingConnections.length &&
                acc.push(component.instanceId);

                return acc;
            },
            []
        );

    const getConnectedComponents = (startComponentId) =>
    {
        const componentList = [];
        let currentComponent = onBoardComponents[startComponentId];

        while (currentComponent) {
            const outgoingConnections = currentComponent.outgoingConnections;
            // we have a linear flow so outgoingConnections.length here is 1
            // or 0 if it's the last component in the process
            const connection = outgoingConnections[0];
            const nextComponentId = outgoingConnections[0]?.end.gridItem;
            const nextComponentSelectorDto = !nextComponentId ? null :
                {
                    type: "DIRECT",
                    nextConfiguredComponentId: nextComponentId,
                    connectionDto: {
                        name: connection.buttonName || null,
                        startPoint: connection.start.node,
                        endPoint: connection.end.node
                    }
                };

            componentList.push({
                componentId: currentComponent.component.id,
                instanceId: currentComponent.instanceId,
                drawingBoardPosition: currentComponent.dropZoneId,
                configurations:
                    currentComponent.configData.configurations ?? {},
                inputMappings: getValidInputMappings(currentComponent),
                nextComponentSelectorDto: nextComponentSelectorDto
            });

            currentComponent = (outgoingConnections.length === 0) ? null :
                onBoardComponents[nextComponentId];
        }

        return componentList;
    };

    const getValidInputMappings = (currentComponent) =>
        Object.values(currentComponent.configData.inputMappings ?? {})
            .filter(mapping => mapping.outputName && mapping.sourceComponentId);

    const handleSaveAsDraft = () => saveApplication(saveDraft)
        .catch(error => {
            catchUnauthorized(error, setUser);
            toast.error(GENERIC_ERROR, TOAST_SETTINGS);
        });

    const isApplicationReady = () =>
        componentsWithoutIncomingConnections().length === 1 &&
        Object.values(onBoardComponents).every(component =>
            component.hasConfigurationsCompleted &&
            component.hasInputMappingsCompleted
        );

    const handleSave = (handleCannotSave) =>
    {
        const applicationReady = isApplicationReady();
        setApplicationComplete(applicationReady);

        !applicationReady ? handleCannotSave() :
            saveApplication(updateApplication).then(reloadAfterSave)
                .catch((error) => {
                    catchUnauthorized(error, setUser);
                    setApplicationComplete(isApplicationComplete);
                    handleFailedSave(error, () => handleCannotSave());
                });
    };

    const handleFailedSave = (errorResponse, action) =>
    {
        if (errorResponse instanceof BadRequest) {
            errorResponse.error.then(e => e.title === "IdeApplications008" &&
                handleNotFoundComponents(e.params.componentIds)
            );

            action();
        } else {
            toast.error(GENERIC_ERROR, TOAST_SETTINGS);
        }
    };

    const appFeeAltered = () => !_.isEqual(initialAppFee, changedAppFee);

    const changeAppFee = () =>
    {
        if (appFeeAltered()) {
            const appId = application.applicationId;
            const changedFeeBN =
                ethers.utils.parseUnits(changedAppFee || "0.0", "ether");

            feLibrary.getAppOwner(appId).then((owner) =>
                owner !== NO_OWNER_ADDRESS ?
                    feLibrary.setAppFee(
                        appId, changedFeeBN, onPaymentProcessing
                    ) :
                    feLibrary.addAppToContract(
                        appId, changedFeeBN, onPaymentProcessing
                    )
            );
        }
    };

    const onPaymentProcessing = () =>
    {
        toast.info(PAYMENT_PROCESSING, TOAST_SETTINGS);
        setInitialAppFee(changedAppFee);
    };

    const handleNotFoundComponents = (componentIds) =>
    {
        const notFoundComponents = Object.values(onBoardComponents)
            .filter(component => componentIds.includes(component.component.id));
        notFoundComponents.forEach(
            component => removeComponent(component.dropZoneId)
        );
        getLatestComponents().then(latest => setLatestComponents(latest));

        notFoundComponents.length > 0 && toast.error(
            buildComponentErrorMessage(
                COMPONENTS_NOT_FOUND,
                Object.values(notFoundComponents).map(c => c.component),
                UPDATE_APPLICATION
            ),
            TOAST_SETTINGS
        );
    };

    const handleDownloadApp = (response) => processDownload(
        downloadApplication(response.id), application.name
    )
        .then(() => reloadAfterSave(response))
        .catch((error) => catchUnauthorized(error, setUser));

    const executeDownload = () => saveApplication(updateApplication)
        .then((response) => response.json())
        .then((response) => Object.keys(unpaidComponents).length === 0 ?
            handleDownloadApp(response) :
            feLibrary.handleDownloadApp(getComponentIds())
                .then(() => handleDownloadApp(response))
                .catch(() => toast.error(REJECTED_PAYMENT, TOAST_SETTINGS))
        )
        .catch(handleError);

    const handleAction = (executeAction) =>
        isDefaultNetwork().then((isDefault) => isDefault ? executeAction() :
            switchToDefaultNetwork()
                .then(() => executeAction())
                .catch(() => {
                    const switchFailed = "Failed to switch to default network.";
                    toast.error(switchFailed, TOAST_SETTINGS);
                })
        );

    const executeDeploy = () => saveApplication(updateApplication)
        .then((response) => response.json())
        .then(doDeployApplication)
        .catch(handleError);

    const doDeployApplication = (appIdDto) =>
    {
        const appId = application.applicationId;
        const deploy = () => deployApplication(appIdDto.id)
            .then(() => reloadAfterSave(appIdDto))
            .then(() => navigateToWizardPrepare(appId))
            .catch((error) => toast.error(error.message, TOAST_SETTINGS));

        let pollCount = 0;
        const pollSubscriptions = () => getActiveSubscriptions(appId)
            .then((subscriptions) => {
                if (subscriptions && subscriptions.length) {
                    return subscriptions;
                }
                if (pollCount === SUBSCRIPTION_POLL_COUNT) {
                    throw new Error("Failed to activate subscription.");
                }
                pollCount++;
                const timeout = new Promise(
                    (resolve) => setTimeout(resolve, 5000)
                );

                return timeout.then(pollSubscriptions);
            });

        const createSubscriptionAndPay = () =>
        {
            const subscription = { type: "ONE_TIME", applicationId: appId };
            const infraFees = ethers.utils.parseUnits(
                configurations.infrastructureFee, "ether"
            );

            return createSubscription(subscription).then((response) =>
                payInfrastructureFee(user.username)
                    .then(tx => registerPayment({
                        paidObjectId: response.id,
                        type: "SUBSCRIPTION",
                        hexTransaction: tx,
                        value: infraFees.toString()
                    }))
                    .then(pollSubscriptions)
                    .catch(() => {
                        throw new Error("Failed to register payment");
                    })
            );
        };

        return getActiveSubscriptions(appId).then((subscriptions) =>
            subscriptions && subscriptions.length ? deploy() :
                createSubscriptionAndPay().then(deploy)
                    .catch((e) => toast.error(e.message, TOAST_SETTINGS))
        );
    };

    const filterPayedComponents = (components) =>
        Object.entries(components).reduce(
            (acc, [key, value]) => {
                const componentNotPayed = !downloadedComponents.includes(
                    value.component.componentId
                );
                if (componentNotPayed) {
                    acc[key] = value;
                }

                return acc;
            },
            {}
        );

    const getComponentIds = () => [...new Set(
        Object.values(filterPayedComponents(onBoardComponents))
            .map(({ component }) => component.componentId)
    )];

    const getFeesTotal = (feeType) => addFees(FEE_GROUPS[feeType]
        .filter((key) => key in extraFees && extraFees[key] !== null)
        .map((key) => extraFees[key])
        .concat(feeType === "downloadPrice" ?
            Object.values(unpaidComponents)
                .map(component => component[feeType]) :
            []
        )
    );

    const getComponentsTotal = (feeType) => addFees(
        Object.values(unpaidComponents).map(component => component[feeType])
    );

    const addFees = (numbersArray) => numbersArray
        .reduce((result, value) => result.add(value), ethers.BigNumber.from(0));

    const handleGoBack = () =>
    {
        if (formHasChanges) {
            !isApplicationReady() ?
                handleSaveAsDraft().then(navigateBackAfterSave) :
                saveApplication(updateApplication)
                    .then(navigateBackAfterSave)
                    .catch((error) => {
                        catchUnauthorized(error, setUser);
                        handleFailedSave(
                            error,
                            () =>
                                handleSaveAsDraft().then(navigateBackAfterSave)
                        );
                    });
        } else {
            navigate(`/application/${id}`);
        }
    };

    const navigateBackAfterSave = (response) => response.json()
        .then((body) => navigate(`/application/${body.id}`));
    const reload = (applicationId) => fetchApplicationVersion(applicationId)
        .then((application) => {
            setUnpaidComponents({});
            setApplication(application);
            setFormHasChanges(false);
            navigate(`/ide/application/${application.id}`, { replace: true });
        })
        .catch((error) => catchUnauthorized(error, setUser));
    const reloadAfterSave = (response) => response.id ?
        reload(response.id) : response.json().then((body) => reload(body.id));
    const navigateToDashboard = () => navigate(`${DASHBOARD}/applications`);
    const navigateToWizardPrepare = (applicationId) =>
        navigate(`/ide/application/${applicationId}/run-wizard`);

    return (
        <div className="row ide">
            <GridContextProvider onChange={ onDragAndDropComponents }>
                <IdeComponentsList
                    dropZones={ dropZones } setFilter={ setFilter }
                />
                <div className="col-md-10 drawing-board">
                    <IdeMenu
                        application={ application }
                        handleSave={ handleSave }
                        handleCancel={ navigateToDashboard }
                        handleDownloadApplication={
                            () => handleAction(executeDownload)
                        }
                        handleDeployApplication={
                            () => handleAction(executeDeploy)
                        }
                        handleSaveAsDraft={
                            () => handleSaveAsDraft().then(reloadAfterSave)
                        }
                        isApplicationComplete={ isApplicationComplete }
                        handleGoBack={ handleGoBack }
                        unpaidComponents={ unpaidComponents }
                        extraFees={ extraFees }
                        getFeesTotal={ getFeesTotal }
                        changedAppFee={ changedAppFee }
                        setChangedAppFee={ setChangedAppFee }
                        changeAppFee={ changeAppFee }
                        appFeeAltered={ appFeeAltered }
                    />
                    <div className="drawing-board-container">
                        <Graphics
                            dropZones={ dropZones }
                            onBoardComponents={ onBoardComponents }
                            getUpstreamOutputs={ getUpstreamOutputs }
                            customizeConnection={ customizeConnection }
                            grid={ grid }
                        />
                        <DrawingBoardDropZones
                            grid={ grid }
                            dropZones={ dropZones }
                            onBoardComponents={ onBoardComponents }
                            setOnBoardComponents={ setOnBoardComponents }
                            customizeComponent={ customizeComponent }
                            customizeConnection={ customizeConnection }
                            removeComponent={ removeComponent }
                            setupModal={ setupModal }
                        />
                        <IdeControls
                            grid={ grid }
                            setGrid={ setGrid }
                            onBoardComponents={ onBoardComponents }
                        />
                    </div>
                </div>
                {
                    configuredComponent &&
                    <div ref={ componentRef } className="position-absolute">
                        <CustomizeComponentSidebar
                            sidebarOpened={ sidebarOpened }
                            configuredComponent={ configuredComponent }
                            updateAndResetSidebar={ updateAndResetSidebar }
                            onBoardComponents={ onBoardComponents }
                        />
                    </div>
                }
                {
                    selectedConnection !== null &&
                    <div ref={ connectionRef } className="position-absolute">
                        <CustomizeConnectionSidebar
                            sidebarOpened={ sidebarOpened }
                            connection={ selectedConnection }
                            setupModal={setupModal }
                            deleteConnection={ deleteConnection }
                            updateAndResetSidebar={ updateAndResetSidebar }
                            onBoardComponents={ onBoardComponents }
                            updateOnBoardComponents={ updateOnBoardComponents }
                            getUpstreamOutputs={ getUpstreamOutputs }
                        />
                    </div>
                }
            </GridContextProvider>
            <ConfirmModal
                show={ showModal }
                setShow={ setShowModal }
                message={ modalDetails.message }
                handleConfirm={ modalDetails.action }
            />
        </div>
    );
};

export default Ide;
