import { configurations } from "../../_common/configurations";
import {
    patch,
    get,
    post,
    checkApiResponse
} from "../../_common/services/webClient";

export const fetchComponents = () =>
    get(configurations.apiUrl + "/components/me").then(checkApiResponse)
        .then(response => response.json());

export const updateComponent = (id, body) =>
    patch(configurations.apiUrl + "/components/" + id, body)
        .then(checkApiResponse);

export const getComponentById = (id) =>
    get(configurations.apiUrl + "/components/" + id).then(checkApiResponse)
        .then(response => response.json());

export const disableComponent = (id) =>
    patch(configurations.apiUrl + "/components/" + id + "/disable")
        .then(checkApiResponse);

export const enableComponent = (id) =>
    patch(configurations.apiUrl + "/components/" + id + "/enable")
        .then(checkApiResponse);

export const getAllTags = () => get(configurations.apiUrl + "/tags/")
    .then(checkApiResponse)
    .then(response => response.json());

export const addTag = (body) => post(configurations.apiUrl + "/tags/", body)
    .then(checkApiResponse)
    .then(response => response.json());

export const getLatestById = (id) =>
    get(configurations.apiUrl + "/components/" + id + "/latest")
        .then(checkApiResponse)
        .then(response => response.json());

export const updateComponentStatus = (id, body) =>
    patch(configurations.apiUrl + "/components/" + id + "/status/", body)
        .then(checkApiResponse);

export const getNumberOfUsages = (id) =>
    get(configurations.apiUrl + "/components/" + id + "/usages")
        .then(checkApiResponse)
        .then(response => response.json());
