import React, { useState, useEffect, useMemo } from "react";
import { GridDropZone, GridItem } from "react-grid-dnd";
import Icon from "../../_common/components/Icon";
import HotspotButton from "./HotspotButton";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../_common/constants/messageStyles";
import {
    COMPONENT_OK,
    COMPONENT_UPDATED,
    NEEDS_CONNECTIONS,
    NOT_CONFIGURED
} from "../common/ideComponentInfo";

const hotspots = ["top", "right", "bottom", "left"];

const DrawingBoardDropZones = (props) =>
{
    const {
        grid,
        dropZones,
        onBoardComponents,
        setOnBoardComponents,
        customizeComponent
    } = props;

    const gridStyle = {
        padding: "15px",
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: `repeat(${grid.cols.length}, 1fr)`,
        gridTemplateRows: `repeat(${grid.rows.length}, 70px)`,
        gridGap: "40px",
        backgroundColor: "transparent"
    };

    const hotspotRefs = useMemo(
        () => hotspots.
            flatMap(hotspot =>
                Object.keys(onBoardComponents).map(key => key + "_" + hotspot)
            )
            .reduce(
                (acc, ref) => {
                    acc[ref] = React.createRef(null);

                    return acc;
                },
                {}
            ),
        [onBoardComponents]
    );
    const [connectionStartPoint, setConnectionStartPoint] = useState(null);

    useEffect(
        () => {
            document.addEventListener("click", handleClickOutside, true);

            return () => document
                .removeEventListener("click", handleClickOutside, true);
        },
        [onBoardComponents]
    );

    const handleClickOutside = (e) => !hotspotRefs[e.target.id]?.current &&
        connectionStartPoint !== e.target.id && setConnectionStartPoint(null);

    const createDropZones = () => grid.rows.map(row => grid.cols.map(col => {
        const dropZoneKey = row + "_" + col;

        return (
            <div key={ dropZoneKey }>
                <GridDropZone
                    className="drop-zone"
                    key={ dropZoneKey }
                    // id has to match the sourceId from dropZones state
                    id={ dropZoneKey }
                    boxesPerRow={ 1 }
                    rowHeight={ 70 }
                >
                    {
                        // Anything you put here will be considered a grid item
                        // anything that's not commented, even if it's not HTML
                        // each dropzone is a list of Components with length=1
                        dropZones[dropZoneKey]?.[0] &&
                        renderGridItem(dropZones[dropZoneKey][0], dropZoneKey)
                    }
                </GridDropZone>
            </div>
        );
    }));

    const renderGridItem = (component, dropZoneKey) =>
    {
        const onGridComponents = Object.values(onBoardComponents).filter(
            component => component.dropZoneId === dropZoneKey.toString()
        );

        if (onGridComponents.length > 0) {
            const gridItemKey = onGridComponents[0].instanceId;
            const componentInfo = getComponentInfo(onGridComponents[0]);
            const className = "dropped-component " + componentInfo.className;

            const onCustomizeComponentButtonClick = () =>
                customizeComponent(onGridComponents[0]);

            const renderHotspot = (position) =>
            {
                const key = gridItemKey + "_" + position;

                return (
                    <div ref={ hotspotRefs[key] } key={ key }>
                        <HotspotButton
                            key={ key }
                            gridItemKey={ gridItemKey }
                            hotspotPosition={ position }
                            configuredComponent={ onGridComponents[0] }
                            handleConnection={ handleConnection }
                            connectionStartPoint={ connectionStartPoint }
                            setConnectionStartPoint={ setConnectionStartPoint }
                        />
                    </div>
                );
            };

            return (
                <GridItem
                    key={ gridItemKey } id={ gridItemKey } className="gridItem"
                >
                    <div className={ className }>
                        <button
                            className="btn delete-component-btn"
                            onClick={ () => deleteComponent(gridItemKey) }
                        >
                            <Icon name="close"/>
                        </button>
                        <div key={ gridItemKey } className="row">
                            <div className="col-sm-2">
                                <button
                                    className="btn customize-component-button"
                                    onClick={ onCustomizeComponentButtonClick }
                                >
                                    <Icon name="threeDots"/>
                                </button>
                            </div>
                            <div className="col-sm-10 component-name">
                                <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title={ componentInfo.message }
                                >
                                    <div>{ component.displayName }</div>
                                </Tooltip>
                            </div>
                        </div>
                        { hotspots.map(renderHotspot) }
                    </div>
                </GridItem>
            );
        }
    };

    const getComponentInfo = (configuredComponent) =>
    {
        if (configuredComponent.isUpdated) {
            return COMPONENT_UPDATED;
        } else if (
            configuredComponent.component.input.length > 0 &&
            configuredComponent.incomingConnections.length === 0
        ) {
            return NEEDS_CONNECTIONS;
        } else if (!configuredComponent.hasConfigurationsCompleted) {
            return NOT_CONFIGURED;
        } else {
            return COMPONENT_OK;
        }
    };

    const deleteComponent = (gridItemKey) => props.setupModal(
        () => props.removeComponent(onBoardComponents[gridItemKey].dropZoneId),
        <>
            <p className="modal-title">
                Are you sure you want to delete the component?
            </p>
            <p className="modal-description">
                You will lose all the configurations and connections for
                the deleted component
            </p>
        </>
    );

    const handleConnection = (e, gridItemKey) =>
    {
        const endPoint = { gridItem: gridItemKey, node: e.target.id };
        !connectionStartPoint ? setConnectionStartPoint(endPoint) :
            makeConnection(connectionStartPoint, endPoint);
    };

    const makeConnection = (start, end) =>
    {
        if (end.gridItem === start.gridItem) {
            toast.error("Cannot connect to itself!", TOAST_SETTINGS);
        } else {
            const connection = { start: start, end: end };
            const startComponent = { ...onBoardComponents[start.gridItem] };
            startComponent["outgoingConnections"] =
                [...startComponent.outgoingConnections, connection];
            const endComponent = { ...onBoardComponents[end.gridItem] };
            endComponent["incomingConnections"] =
                [...endComponent.incomingConnections, connection];

            setOnBoardComponents({
                ...onBoardComponents,
                [start.gridItem]: startComponent,
                [end.gridItem]: endComponent,
            });
            setConnectionStartPoint(null);
            props.customizeConnection(connection);
        }
    };

    return (
        <div className="board" style={ gridStyle }>
            { createDropZones() }
        </div>
    );
};

export default DrawingBoardDropZones;
